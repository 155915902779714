import {
    observer
} from 'mobx-react-lite';
import { Container, IconButton } from '../../controls';
import WeddingSpaceEditor from '../../../api/space/editor';
import WeddingSpace from '../../../api/space';


const EditorComponentToolbar = observer(({ sdk, component, direction, movePossible }: any) => {
    const useSpace: WeddingSpace | null = sdk?.space || null;
    const useSpaceEditor: WeddingSpaceEditor | null = useSpace?.editor || null;
    const moveIcon = direction === 'up' ? 'icon:arrow_up.svg' : 'icon:arrow_down.svg';
    // const disableButton: boolean = direction === 'up';
    const titleButton: boolean = direction === 'up';
    const titleVisible: boolean = component?.title?.active || false;

    return <Container
        containerOption={{
            width: '100%',
            heightPx: 80,
            float: 'left',
            textAlign: 'center',
            bgColor: useSpace?.palette?.tertiary || '#ff00ff'
        }}
    >
        {titleButton ? (
            <IconButton
                buttonOption={{
                    widthPx: 64,
                    heightPx: 64,
                    margin: [8, 0, 0, 16],
                    float: 'left'
                }}
                source={'icon:title.svg'}
                onClick={() => useSpaceEditor?.setTitleVisibility(component.id, !titleVisible)}
            />
        ) : null}
        <IconButton
            buttonOption={{
                widthPx: 64,
                heightPx: 64,
                margin: [8, 0, 0, 0]
            }}
            disabled={!movePossible}
            source={moveIcon}
            onClick={() => direction === 'up' ? useSpaceEditor?.moveComponentUp(component.id) : useSpaceEditor?.moveComponentDown(component.id)}
        />
    </Container>;
});


export default EditorComponentToolbar;