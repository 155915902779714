import { action, computed, makeObservable, observable } from "mobx";
import getSdk, { ApplicationSdk } from "..";

class WhishList {

    public whishes: Array<any>;
    public playedWhished: Array<any>;

    public spaceHash: string;
    public spaceName: string;

    public autoUpdate: any = null;
    public viewAll: boolean = false;

    public maxWhishCount: number;

    constructor(spaceHash: string, spaceName: string) {
        makeObservable(this, {
            maxWhishCount: observable,
            whishes: observable,
            playedWhished: observable,
            viewAll: observable,
            fetchAll: action,
            fetchAllPlayed: action,
            updateWhishList: action,
            switchList: action,
            priobacklog: computed,
            whishInfo: computed,
            whishCapacity: computed,
            backlog: computed
        });

        this.playedWhished = [];
        this.whishes = [];
        this.spaceHash = spaceHash;
        this.spaceName = spaceName;
        this.viewAll = false;
        this.maxWhishCount = 500;

    }

    public async create(content: string): Promise<any> {
        const useSdk: ApplicationSdk = getSdk();

        const spinner: any = useSdk.createSpinner('Sende Wunsch...', false, 10000);

        const addWhish: any = await useSdk.fetchPostApi(`space/${this.spaceHash}/${this.spaceName}/whish`, {
            content: `${content}`.trim()
        })

        spinner.close();

        if (addWhish?.status === 201) {
            // Show Success Message
            useSdk.openDialog('Wunsch gesendet', 'Dein Wunsch wurde erfolgreich gesendet.');
        } else if (addWhish?.status === 429) {
            useSdk.openDialog('Fehler', 'Du kannst nur einen Wunsch pro Minute absenden');
        }
    }

    public switchList(): void {
        this.viewAll = !this.viewAll;

        if (this.viewAll) {
            this.fetchAllPlayed()
        }
    }

    public updateWhishList(data: any): void {
        if (data) {
            if (data.type === 'all') {
                this.whishes = data.whishList || [];
            } else if (data.type === 'played') {
                this.playedWhished = data.whishList || [];
            }
            this.maxWhishCount = data.maxWhish || 500;
        }
    }

    public enableAutoUpdate(sdk: any): void {
        if (!sdk.hasInterval('whish')) {
            const that = this;
            this.fetchAll();
            sdk.addInterval('whish', setInterval(() => {
                that.fetchAll();
            }, 30000))
        }
    }

    public async deleteWhish(id: number): Promise<any> {
        const useSdk: ApplicationSdk = getSdk();
        const spinner: any = useSdk.createSpinner('Lösche Wunsch...', false, 10000);
        const delResult: any = await useSdk.fetchDeleteApi(`space/${this.spaceHash}/${this.spaceName}/whish/${id}`)
        spinner.close();

        if (delResult?.status === 200) {
            this.updateWhishList(delResult?.json)
        }
    }

    public async playedWhish(id: number): Promise<any> {
        const useSdk: ApplicationSdk = getSdk();
        const spinner: any = useSdk.createSpinner('Bitte warten...', false, 10000);
        const delResult: any = await useSdk.fetchPutApi(`space/${this.spaceHash}/${this.spaceName}/whish/${id}/played`)
        spinner.close();

        if (delResult?.status === 200) {
            this.updateWhishList(delResult?.json);
            this.fetchAll(true)
        }
    }

    public async downgradeWhish(id: number): Promise<any> {
        const useSdk: ApplicationSdk = getSdk();
        const spinner: any = useSdk.createSpinner('Bitte warten...', false, 10000);
        const delResult: any = await useSdk.fetchPutApi(`space/${this.spaceHash}/${this.spaceName}/whish/${id}/unprioritize`)
        spinner.close();

        if (delResult?.status === 200) {
            this.updateWhishList(delResult?.json)
        }
    }

    public async upgradeWhish(id: number): Promise<any> {
        const useSdk: ApplicationSdk = getSdk();
        const spinner: any = useSdk.createSpinner('Bitte warten...', false, 10000);
        const delResult: any = await useSdk.fetchPutApi(`space/${this.spaceHash}/${this.spaceName}/whish/${id}/prioritize`)
        spinner.close();

        if (delResult?.status === 200) {
            this.updateWhishList(delResult?.json)
        }
    }

    public async fetchAll(force: boolean = false): Promise<any> {
        const useSdk: ApplicationSdk = getSdk();

        const whishResult: any = await useSdk.fetchGetApi(`space/${this.spaceHash}/${this.spaceName}/whish/all`)

        if (whishResult?.status === 200) {
            this.updateWhishList(whishResult?.json);
        }
    }

    public async fetchAllPlayed(): Promise<any> {
        const useSdk: ApplicationSdk = getSdk();

        const whishResult: any = await useSdk.fetchGetApi(`space/${this.spaceHash}/${this.spaceName}/whish/played`)

        if (whishResult?.status === 200) {
            this.updateWhishList(whishResult?.json);
        }
    }

    get priobacklog() {
        return this.whishes.filter((w: any) => w.sortOrder !== null)
    }

    get backlog() {
        return this.whishes.filter((w: any) => w.sortOrder === null)
    }

    get whishInfo() {
        return `${this.whishes.length} / ${this.maxWhishCount} Wünsche`
    }

    get whishCapacity() {
        // return 100;
        return Math.round((this.whishes.length / this.maxWhishCount) * 100)
    }
}

export default WhishList;