import { observer } from "mobx-react-lite";
import { Container, IconButton, Title } from "../../controls";
import SongItemBlock from "./SongItemBlock";
import SwitchBlock from "./Switcher";

const DjAdminBlock = observer(({
    id,
    sdk
}: any) => {
    const useSpace = sdk.space;
    const useWhishList = useSpace?.whishList;

    return useWhishList ? <>
        <Container
            id={id}
            containerOption={{
                position: 'sticky',
                float: 'left',
                width: '100%',
                bgColor: '#855495',
                borderSize: 6,
                borderBottom: 'solid #51315c',
                zIndex: 12
            }}
        >
            <Title size={48} className={'fonthead'} titleOptions={{ fontWeight: 'normal', lineHeight: 96, position: 'relative' }}>
                <IconButton
                    id={`dj-button`}
                    buttonOption={{
                        position: 'absolute',
                        left: 8,
                        top:8,
                        widthPx: 64,
                        heightPx: 64
                    }}
                    source={'icon:cancel.svg'}
                    onClick={() => useSpace.switchView(null)}
                />
                DJ Ansicht
            </Title>
            <Container
                containerOption={{
                    float: 'right',
                    margin: [0, 16, 0, 0],
                    color: useWhishList.whishCapacity < 80 ?
                        '#fff' :
                        useWhishList.whishCapacity < 90 ?
                            'yellow' :
                            'red'
                }}
            >
                {useWhishList.whishCapacity >= 90 ? 'Wunschkapazität fast erreicht! - ' : ''}{useWhishList.whishInfo}
            </Container>
        </Container>
        <SwitchBlock whishList={useWhishList} />
        {useWhishList.viewAll ? (
            <>
                {(useWhishList.playedWhished || []).map((item: any, i: number) => {
                    return <SongItemBlock
                        id={`whish-${i}`}
                        item={item}
                        type={'played'}
                        titleSize={20}
                        key={`text-${i}`}
                        sdk={sdk}
                    />;
                })}
            </>
        ) : null}
        <Title
            titleOptions={{
                fontSize: 32,
                bgColor: useSpace?.palette?.secondaryLight,
                color: useSpace?.palette?.secondaryText,
            }}
            editable={false}
            value={'Gästewünsche Priorisiert'}
        />
        {(useWhishList.priobacklog || []).map((item: any, i: number) => {
            return <SongItemBlock
                id={`whish-${i}`}
                type={'priobacklog'}
                item={item}
                titleSize={20}
                key={`text-${i}`}
                sdk={sdk}
            />;
        })}
        <Title
            titleOptions={{
                fontSize: 32,
                bgColor: useSpace?.palette?.secondaryLight,
                color: useSpace?.palette?.secondaryText,
            }}
            editable={false}
            value={'Gästewünsche Backlog'}
        />
        {(sdk.space?.whishList.backlog || []).map((item: any, i: number) => {
            return <SongItemBlock
                id={`whish-${i}`}
                item={item}
                titleSize={20}
                key={`text-${i}`}
                sdk={sdk}
            />;
        })}
    </> : null;
});

export default DjAdminBlock;
// export { SongItemBlock };