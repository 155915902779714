import {
    observer
} from 'mobx-react-lite';
import { IconButton, Container } from '../../controls';
import { elapsedTimeString } from '../../../helper';

const SongItemBlock = observer(({
    id,
    title,
    active,
    sdk,
    type,
    item
}: any) => {
    const isPlayed = type === 'played'
    const isPrioBacklog = type === 'priobacklog'
    return <Container
        id={id}
        containerOption={{
            float: 'left',
            width: '100%',
            bgColor: '#fff',
            borderSize: 2,
            borderBottom: 'solid #000'
        }}
    >
        <Container
            id={id}
            containerOption={{
                float: 'left',
                width: '100%',
                textAlign: 'right',
                fontSize: 18
            }}
        >
            <Container
                containerOption={{
                    float: 'left'
                }}
            >

            </Container>
            <Container
                containerOption={{
                    float: 'right',
                    fontSize: 20,
                    lineHeight: 48,
                    margin: [0, 8, 0, 0],
                    color: '#000'
                }}
            >
                {elapsedTimeString(item.time)}
            </Container>
        </Container>
        <Container
            id={id}
            containerOption={{
                float: 'left',
                widthPx: 80,
                lineHeight: 80,
                textAlign: 'center',
                fontSize: 30,
                color: 'rgb(153, 153, 153)'
            }}
        >
            {!isPlayed && isPrioBacklog ? <IconButton id={`button-unprio-whish-${item.id}`} buttonOption={{ widthPx: 64, heightPx: 64, margin: [8, 0, 0, 8] }} source={'icon:downgrade.svg'} onClick={() => sdk.space.whishList.downgradeWhish(item.id)} /> : null}
            {!isPlayed && !isPrioBacklog ? <IconButton id={`button-delete-whish-${item.id}`} buttonOption={{ widthPx: 64, heightPx: 64, margin: [8, 0, 0, 8], float: 'right' }} source={'icon:delete.svg'} onClick={() => sdk.space.whishList.deleteWhish(item.id)} /> : null}
        </Container>
        <Container
            id={id}
            containerOption={{
                float: 'left',
                widthPx: 540,
                heightPx: 80,
                textAlign: 'center',
                fontWeight: 'bold',
                overflow: 'hidden',
                wordBreak: 'break-word',
                // whiteSpace: 'nowrap',
                fontSize: 24,
                color: 'rgb(153, 153, 153)'
            }}
        >
            {item.text}
        </Container>
        <Container
            id={id}
            containerOption={{
                float: 'left',
                widthPx: 80,
                lineHeight: 80,
                textAlign: 'center',
                fontSize: 22,
                fontWeight: 'bold'
            }}
        >
            {!isPlayed && isPrioBacklog ? <IconButton id={`button-accept-whish-${item.id}`} buttonOption={{ widthPx: 64, heightPx: 64, margin: [8, 0, 0, 8] }} source={'icon:accept.svg'} onClick={() => sdk.space.whishList.playedWhish(item.id)} /> : null}
            {!isPlayed && !isPrioBacklog ? <IconButton id={`button-prio-whish-${item.id}`} buttonOption={{ widthPx: 64, heightPx: 64, margin: [8, 0, 0, 8] }} source={'icon:upgrade.svg'} onClick={() => sdk.space.whishList.upgradeWhish(item.id)} /> : null}

        </Container>
    </Container>
});

export default SongItemBlock;