import { action, makeObservable, observable } from "mobx";
import LayoutManager from "./layoutmanager";
import WeddingSpace, { createSpaceFromPath } from "./space";
import { uuidv4 } from "../helper";
import axios from "axios";
import ImageCropper from "./cropper";

const wait = (ms: number) => new Promise(
    (res, rej) => setTimeout(
        () => rej(new Error(`timed out after ${ms} ms`)),
        ms
    )
);

const getLocalStorage = (sessionStore: boolean = false): Storage => {
    // Mobile Games only use the localStorage
    // if (process.env.REACT_APP_MOBILE !== "false" || forceLocalstorage) {
    //     return localStorage;
    // }
    // this is only for the websessions
    return sessionStore ? sessionStorage : localStorage;
}

const getAppName = (): string => {
    return process.env.REACT_APP_WEDDINGWONDERS_NAME || '';
}

const convertAToB = (data: any): any => {
    return process.env.REACT_APP_DEVELOPMENT === 'true' ? data : atob(data);
}
const convertBToA = (data: any): any => {
    return process.env.REACT_APP_DEVELOPMENT === 'true' ? data : btoa(data);
}

interface FETCH_OPTIONS {
    spinner?: boolean;
    multiPart?: boolean;
    timeout?: number;
}

const toBase64 = (file: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    })
};

class ApplicationSdk {
    public layoutManager: LayoutManager;
    public space: WeddingSpace | null;
    public clientId: string | null = null;
    public clientToken: string | null = null;
    public spinner: any = null;
    public dialog: any = null;
    public registeredIntervals: Array<any> = [];

    constructor() {
        makeObservable(this, {
            space: observable,
            dialog: observable,
            destroySpace: action,
            init: action,
            openSetting: action,
            openMediaUploader: action,
            loadSpace: action,
            openNewDialog: action,
            openNewComponentDialog: action,
            openDialog: action,
            openImagePreview: action,
            closeDialog: action,
            createSpinner: action,
            updateSpinnerProgress: action,
            closeSpinner: action,
            spinner: observable
        });
        this.layoutManager = new LayoutManager(this);
        this.space = null;

        this.registerEvents();

        // @ts-ignore
        window.sdk = this;
    }

    public hasInterval(type: string): boolean {
        return !!this.registeredIntervals.find((c: any) => c.type === type);
    }

    public clearAllIntervals(): void {
        this.registeredIntervals.forEach((c: any) => {
            clearInterval(c.interval);
        });
        this.registeredIntervals = [];
    }

    public addInterval(type: string, interval: any): void {
        this.registeredIntervals.push({
            type,
            interval
        });
    }

    public openImagePreview(file: any): void {
        this.dialog = {
            type: 'image',
            file
        }
    }

    public openDeleteDialog(title: string, message: string, deleteCode: string, cbYes: any = null, cbNo: any = null): void {
        this.dialog = {
            type: 'delete',
            message,
            deleteCode,
            title,
            cbYes,
            cbNo
        }
    }

    public openDialog(title: string, message: string, cbYes: any = null, cbNo: any = null): void {
        this.dialog = {
            type: 'dialog',
            message,
            title,
            cbYes,
            cbNo
        }
    }

    public openNewComponentDialog(componentId: any): void {
        this.dialog = {
            type: 'newcomponent',
            addBefore: componentId
        }
    }

    public openSetting(view: string = 'general'): void {
        this.dialog = {
            type: 'setting',
            view
        }
    }

    public async openMediaUploader(files: any, aspectRatio: any, onUpload: any): Promise<any> {
        const useFile: any = await toBase64(files.length ? files[0] : files);

        this.dialog = {
            type: 'mediapicker',
            onUpload,
            store: new ImageCropper(useFile, aspectRatio)
        }
    }

    public openDropdown(items: Array<any> | null = null, onClose: any = null): void {
        this.dialog = {
            type: 'dropdown',
            items,
            onClose
        }
    }

    public openNewDialog(): void {
        this.dialog = {
            type: 'dialog',
            size: {
                width: 600,
                height: 800
            }
        }
    }

    public closeDialog(autosave: boolean = false): void {
        if (autosave) {
            if (this.space?.hasEditorUpdate) {
                this.space.save();
            }
        }
        this.dialog = null;
    }

    public registerEvents(): void {
        const that = this;
        window.addEventListener(
            "hashchange",
            (y) => {
                that.locationHashChanged();
            },
            false,
        );
    }

    public locationHashChanged() {
        const newHash: string | null = window?.location?.hash || null;

        if (newHash && this.space) {
            this.space.switchView(newHash)
        }

    }

    public async init(): Promise<void> {
        const path: string | undefined = 'wedding/aaaaaaaaaa/nadine-york-2024'; //window?.location?.pathname;
        const searchData: string | undefined = window?.location?.search;
        // const searchParams: URLSearchParams = new URLSearchParams(searchData);
        let clientToken: string | null = '697337c35b43470590ff6759113c9644';
        // wedding/aaaaaaaaaa/nadine-york-2024?token=697337c35b43470590ff6759113c9644

        // if (searchParams?.has('token')) {
        //     clientToken = searchParams.get('token') || null;
        // }

        // reset search Params
        // window.history.replaceState({}, document.title, window.location.pathname);

        this.initClient(clientToken);

        this.space = createSpaceFromPath(this, path || '')

        if (this.space) {
            const loadResult: any = await this.space.load(true);

            if (loadResult !== true) {
                this.handleErrorMessage(loadResult?.errorName)
                this.destroySpace();
            }
        }
    }

    public async destroySpace(): Promise<void> {
        if (this.space) {
            this.space.destroy();
            this.space = null;
        }
    }

    public async loadSpace(): Promise<void> {
        if (this.space) {
        }
    }

    public updateSpinnerProgress(progress: number): any {
        if (this.spinner) {
            this.spinner.progress = progress;
        }
    }

    public createSpinner(customMessage: string | null = null, progress: boolean = false, spinnerTimeout: number = 9000): any {
        this.closeSpinner();

        this.spinner = {
            timeout: setTimeout(() => {
                this.closeSpinner();
            }, spinnerTimeout),
            progress: progress ? 0 : null,
            message: customMessage || 'Bitte warten...'
        }

        return {
            close: () => this.closeSpinner()
        }
    }

    public closeSpinner(): void {
        if (this.spinner) {
            if (this.spinner.timeout) {
                clearTimeout(this.spinner.timeout);
            }
        }
        this.spinner = null;
    }

    private saveClientToken(clientToken: string | null): string | null {
        let authToken = this.loadFromStorage('token', true) || null;

        if (clientToken) {
            if (clientToken !== authToken) {
                this.saveInStorage('token', clientToken, true);
            }
            return clientToken;
        }
        return authToken;
    }

    private createClientId(): string {
        let uuidAuthToken = this.loadFromStorage('client') || '';

        if (!uuidAuthToken) {
            uuidAuthToken = `${uuidv4().slice(-8)}${uuidv4()}`;
            this.saveInStorage('client', uuidAuthToken);
        }
        return uuidAuthToken;
    }

    public initClient(clientToken: string | null): void {
        this.clientId = this.createClientId();
        this.clientToken = this.saveClientToken(clientToken);

    }

    public loadFromStorage(key: string, forceSessionStorage: boolean = false) {
        const useKey = `${getAppName()}${key}`;

        if (getLocalStorage(forceSessionStorage)) {
            const data = getLocalStorage(forceSessionStorage).getItem(convertBToA(useKey));
            if (data) {
                try {
                    if (typeof data === 'boolean') return data;
                    if (data === 'false') return false;
                    return JSON.parse(convertAToB(data));
                } catch (e) {
                    return convertAToB(data);
                }
            }
        }
        return undefined;
    }

    public saveInStorage(key: string, value: any, forceSessionStorage: boolean = false) {
        const useKey = `${getAppName()}${key}`;

        if (getLocalStorage(forceSessionStorage)) {
            if (typeof value === 'object') {
                getLocalStorage(forceSessionStorage).setItem(convertBToA(useKey), convertBToA(JSON.stringify(value)));
            } else if (typeof value === 'string') {
                getLocalStorage(forceSessionStorage).setItem(convertBToA(useKey), convertBToA(value));
            } else if (typeof value === 'boolean') {
                //@ts-ignore
                getLocalStorage(forceSessionStorage).setItem(convertBToA(useKey), value);
            }
        }
    }



    public async fetchGetApi(path: string, data: any = {}, options: FETCH_OPTIONS = {}): Promise<any> {
        return this.fetchApi(data, 'GET', path, {
            timeout: 4000,
            spinner: true,
            ...options
        })
    }

    public async fetchUploadFile(path: string, files: any): Promise<any> {

        const useTimeout = 40000
        const spinner = this.createSpinner('Uploade Medien...', true, useTimeout);
        const formData = new FormData();

        Array.from(files).forEach((file: any) => {
            console.log('APPEND', file)
            formData.append('files', file);
        })

        const that = this;

        const config = {
            onUploadProgress: (progressEvent: any) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                that.updateSpinnerProgress(percentCompleted)
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-api-key': that.clientToken ? `${that.clientId}|${that.clientToken}` : that.clientId,

            },
            timeout: useTimeout
        }

        let useUrl = `${process.env.REACT_APP_WEDDINGWONDERS_API}${path}`;



        let postResult = null;

        try {
            postResult = await axios.post(useUrl, formData, config)
        } catch (e) {

        }

        spinner.close();

        if (postResult?.status === 201) {
            this.openDialog('Medien hochgeladen', 'Der Upload der Medien war erfolgreich');
        } else if (postResult?.status === 403) {
            this.openDialog('Fehler', 'Fehler beim Upload. Probiere es erneut');
        }

        // if (postResult?.status !== 200 && postResult?.status !== 201) {
        //     this.handleErrorMessage(postResult?.status, postResult?.json)
        // }
        return postResult;
    }

    public async fetchPostApi(path: string, data: any = {}, options: FETCH_OPTIONS = {}): Promise<any> {
        const postResult: any = await this.fetchApi(data, 'POST', path, {
            timeout: 4000,
            spinner: true,
            ...options
        })

        if (postResult?.status !== 200 && postResult?.status !== 201) {
            // this.handleErrorMessage(postResult?.status, postResult?.json)
        }
        return postResult;
    }

    public async fetchPutApi(path: string, data: any = {}, options: FETCH_OPTIONS = {}): Promise<any> {
        return this.fetchApi(data, 'PUT', path, {
            timeout: 4000,
            spinner: true,
            ...options
        })
    }

    public async fetchDeleteApi(path: string, data: any = {}, options: FETCH_OPTIONS = {}): Promise<any> {
        return this.fetchApi(data, 'DELETE', path, {
            timeout: 4000,
            spinner: true,
            ...options
        })
    }

    private async fetchApi(data: any = {}, method: string = 'POST', path: string = '', options: any = {
        timeout: 4000,
        spinner: true,
        multiPart: false
    }): Promise<any> {
        const that = this;

        // it should be possible to use own uri
        let useUrl = `${process.env.REACT_APP_WEDDINGWONDERS_API}${path}`;
        let useSpinner: any = null;
        //  let apiKeyRequired: boolean = true; // Is the apiKey required?

        if (path.startsWith('https')) {
            useUrl = path;
            //    apiKeyRequired = false;
        } else if (path.startsWith('account:')) {
            useUrl = `${path.replace('account:', `${process.env.REACT_APP_ACCOUNTAPI}`)}`;
            //  apiKeyRequired = false;
        }

        if (options.spinner) {
            useSpinner = this.createSpinner(null, false, options?.timeout);
        }

        const isGet = method === 'GET';

        if (isGet) {
            const reqQuery = Object.entries({
                ...data
            }).map((c, i) => `${i > 0 ? '&' : ''}${c[0]}=${c[1]}`).join('')
            useUrl = reqQuery ? `${useUrl}?${reqQuery}` : useUrl;
        }

        return new Promise((resolve, reject) => {
            const abortController = new AbortController();
            const fetchData = fetch(useUrl, {
                method,
                signal: abortController.signal,
                // @ts-ignore
                headers: {
                    ...(options?.multiPart === true ? {} : { 'Content-Type': 'application/json' }),
                    'x-api-key': that.clientToken ? `${that.clientId}|${that.clientToken}` : that.clientId,
                },
                body: options?.multiPart === true ? data : isGet ? undefined : JSON.stringify({
                    ...data
                })
            });

            const fetchOrTimeout = Promise.race([fetchData, wait(options.timeout)]);

            fetchOrTimeout
                .then(async (response: any) => {
                    if (useSpinner) useSpinner.close();
                    let jsonResult = null;
                    try {
                        jsonResult = await response?.json()
                    } catch (e) {
                    }
                    resolve({
                        json: jsonResult || null,
                        status: response.status
                    })
                })
                .catch((error: any) => {
                    if (useSpinner) useSpinner.close();
                    abortController.abort()
                    resolve(undefined)
                });
        })

    }
    private handleErrorMessage(errorName: string): void {
        switch (errorName) {
            case 'SPACE_NOT_FOUND':
                this.openDialog('Space nicht gefunden', 'Der Space konnte nicht gefunden werden. Bitte versuche es erneut');
                break;
            case 'SPACE_NOT_ACTIVE':
                this.openDialog('Space nicht aktiv', 'Der Space ist aktuell noch nicht aktiviert. Wenn du der Besitzer bist, schau deine letzte Email an');
                break;
            default:
                this.openDialog('Fehler', 'Der Space konnte nicht geladen werden');
                break;
        }
    }

    get currentSpace(): WeddingSpace | null {
        return this.space;
    }
}

let WeddingSDK: ApplicationSdk | undefined = undefined;

const getSdk = () => {
    if (!WeddingSDK) {
        WeddingSDK = new ApplicationSdk();
        WeddingSDK.init();
    }

    return WeddingSDK;
}

export default getSdk;
export {
    ApplicationSdk
}