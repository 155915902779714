import {
    observer
} from 'mobx-react-lite';
import {
    ActionButton,
    Container,
    CropperComponent,
    IconButton
} from '../../controls/index';

const MediaUploadDialog = observer(({ sdk }: any) => {
    const {
        layoutManager
    } = sdk;

    const dialog = sdk.dialog?.type === 'mediapicker' ? sdk.dialog : null;
    const useStore = dialog?.store || null;

    const dialogWidth: number = 640;
    const dialogHeight: number = 640;

    return dialog ? <div
        id={'gamedialog'}
        onClick={() => {
            sdk.closeDialog(true);
            if (dialog?.onClose) dialog.onClose();
        }}
        style={{}}
    >
        <div
            id={'gamedialog_string_wrapper'}
            key={1}
            style={layoutManager.calculateCanvas}
        >
            <Container
                id={'gamedialog_string_content'}
                containerOption={{
                    widthPx: dialogWidth,
                    heightPx: dialogHeight,
                    borderRadius: 16,
                    zIndex: 60,
                    bgColor: '#fff',
                    margin: [(1280 - dialogHeight) / 2, 0, 0, (720 - dialogWidth) / 2]
                }}
                onClick={(event: any) => {
                    event.stopPropagation();
                }}
            >
                <Container
                    id={'gamedialog_string_message'}
                    containerOption={{
                        position: 'relative',
                        width: '100%',
                        heightPx: 80,
                        lineHeight: 80,
                        borderCorner: [16, 16, 0, 0],
                        color: '#fff',
                        bgColor: '#855495',
                        fontSize: 32,
                        fontWeight: 'bold',
                        textAlign: 'center',
                        margin: [0, 0, 0, 0]
                    }}
                >
                    Upload
                    <IconButton
                        id={'close-dialog'}
                        source={'icon:cancel.svg'}
                        buttonOption={{
                            widthPx: 64,
                            heightPx: 64,
                            position: 'absolute',
                            right: 8,
                            top: 8
                        }}
                        onClick={() => {
                            sdk.closeDialog(true)
                            if (dialog?.onClose) dialog.onClose();
                        }}
                    />
                </Container>
                <Container
                    containerOption={{
                        overflowY: 'auto',
                        widthPx: 620,
                        heightPx: 540,
                        margin: [10, 0, 0, 10]
                    }}
                >
                    <Container
                        containerOption={{
                            position: 'relative',
                            widthPx: 600,
                            heightPx: 400,
                            margin: [10, 0, 0, 10]
                        }}
                    >
                        <CropperComponent
                            store={useStore}
                            containerOption={{
                                widthPx: 380,
                                heightPx: 380,
                                left: 128,
                            }}
                        />
                    </Container>
                    <IconButton
                        source={'icon:next1.svg'}
                        buttonOption={{
                            float: 'left',
                            widthPx: 80,
                            heightPx: 80,
                            margin: [16, 0, 0, 120]
                        }}
                        enableDelay={false}
                        disabled={useStore.zoom <= 1}
                        onClick={() => useStore.updateValue('zoom', useStore.zoom - 0.1)}
                    />
                    <IconButton
                        source={'icon:next1.svg'}
                        buttonOption={{
                            float: 'left',
                            widthPx: 80,
                            heightPx: 80,
                            margin: [16, 0, 0, 240]
                        }}
                        disabled={useStore.zoom >= 3}
                        enableDelay={false}
                        onClick={() => useStore.updateValue('zoom', useStore.zoom + 0.1)}
                    />

                </Container>
            </Container>
            <ActionButton
                id={'btn-close'}
                icon={'icon:upload.svg'}
                buttonOption={{
                    widthPx: 280,
                    margin: [16, 0, 0, 220],
                    padding: [0, 0, 0, 0],
                    lineHeight: 48,
                    outlineBorderSize: 4,
                    outlineBorder: '#5b3368'
                }}
                iconOption={{
                    widthPx: 48,
                    heightPx: 48,
                    margin: [0, 0, 0, 0]
                }}
                enableDelay={200}
                actionColor={'green'}
                onClick={async () => {
                    const croppedImage = await useStore.getCroppedImg();
                    sdk.closeDialog(true)
                    if (dialog?.onUpload) dialog.onUpload(croppedImage);
                }}
            >
                Upload
            </ActionButton>
        </div>
    </div > : null;
});

export default MediaUploadDialog;