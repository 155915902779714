import {
    observer
} from 'mobx-react-lite';
import getSdk from '../../../api'
import { IconDisc, IconRing, IconRing2, IconSave, IconSendMessage, IconSetting } from '../../../icons';

const IconSvg = observer(({
    id,
    icon,
    iconOption,
    className,
    width,
    disabled = false,
    height,
    color,
    label = null,
    onClick
}: any) => {
    const sdk = getSdk();
    const useStyles = sdk.layoutManager.styles

    const propsIcon = useStyles({
        widthPx: width || 64,
        heightPx: height || 64,
        border: 'none',
        ...(label ? {
            lineHeight: height || 64,
            textAlign: 'center'
        } : {}),
        ...(iconOption || {}),
        ...(disabled ? {
            opacity: 0.4
        } : {})
    });
    let IconComp = null;

    if (icon === 'svg>ring') {
        IconComp = IconRing
    } else if (icon === 'svg>ring2') {
        IconComp = IconRing2
    } else if (icon === 'svg>disc') {
        IconComp = IconDisc
    } else if (icon === 'svg>save') {
        IconComp = IconSave
    } else if (icon === 'svg>setting') {
        IconComp = IconSetting
    } else if (icon === 'svg>sendmessage') {
        IconComp = IconSendMessage
    }
    
    return <div
        id={id}
        className={className}
        style={propsIcon}
        onClick={(event: any) => {
            if (!disabled && onClick) {
                onClick(event)
            }
        }}
    >
        {IconComp ? <IconComp
            fill={color}
        /> : null}
    </div>
});

export default IconSvg;