import {
    observer
} from 'mobx-react-lite';
import { ImageComponent, Container } from '../../../controls/index';
import { getMediaSrc } from '../../../../helper';

const HeadImageBlock = observer(({
    sdk
}: any) => {
    return <Container
        id={`canvas-head-container`}
        containerOption={{
            float: 'left',
            width: '100%',
            bgColor: '#fff'
        }}
    >
        <ImageComponent
            id={`canvas-head-image`}
            source={getMediaSrc(sdk.space, 'title', 'test:brautpaar2.jpg')}
            imageOptions={{
                width: '100%',
                bgImageSize: 'contain'
            }}
        />
    </Container>;
});

export default HeadImageBlock;