import { action, makeObservable, observable, computed } from "mobx";
import getCroppedImg from "../helper";

class ImageCropper {
    public original: any;

    public crop: any;
    public zoom: any;
    public croppedAreaPixels: any;

    public cropAspect: any;

    constructor(file: any, aspect: any) {
        makeObservable(this, {
            crop: observable,
            zoom: observable,
            cropAspect: observable,
            aspectRatio: computed,
            updateValue: action
        })
        this.crop = { x: 0, y: 0 };
        this.zoom = 1;
        this.cropAspect = aspect;

        this.original = file;
    }

    public updateValue(property: string, value: any) {
        // @ts-ignore
        this[property] = value;
    }

    public async getCroppedImg(): Promise<any> {
        return getCroppedImg(
            this.original,
            this.croppedAreaPixels,
            0
        );
    }

    get aspectRatio() {
        return this.cropAspect[0] / this.cropAspect[1];
    }
}

export default ImageCropper;