const SPACE_TILES = [
    {
        label: 'Text',
        type: 'text',
        config: {
            title: {
                value: 'Neuer Text',
                active: true
            }
        }
    },
    {
        label: 'Dj Block',
        type: 'djwhish',
        config: {
            title: {
                value: 'Wünsch dir einen Song',
                active: true
            },
            value: {
                header: 'Was möchtest du hören?',
                placeholder: 'Gebe deinen Wunsch ein...',
                footer: 'https://www.weddingwonders.de/meine-hochzeit'
            }
        }
    }
]

export default SPACE_TILES;