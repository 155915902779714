import {
    makeObservable,
    observable,
    action,
    computed
} from 'mobx'
import getCss from './csshelper'

const PORTRAIT_CALCULATED_WIDTH = 720;
const PORTRAIT_CALCULATED_HEIGHT = 1280;
const LANDSCAPE_CALCULATED_WIDTH = 1280;
const LANDSCAPE_CALCULATED_HEIGHT = 720;


const getWindow: any = () => {
    return window;
}

export default class LayoutManager {

    private initialRender: any;
    private sdk: any;

    public width: number;
    public height: number;
    public scaleX: number;
    public scaleY: number;
    public offsetX: number;
    public offsetY: number;

    // Which resolution is used?
    public calcWidth: number;
    public calcHeight: number;

    public renderWidth: number;
    public renderHeight: number;

    public alignHorizontal: string;
    public alignVertical: string;

    public verticalCentered: boolean;
    public portraitMode: boolean;

    constructor(sdk: any) {
        makeObservable(this, {
            width: observable,
            height: observable,
            renderWidth: observable,
            renderHeight: observable,
            offsetX: observable,
            offsetY: observable,
            verticalCentered: observable,
            portraitMode: observable,
            scaleX: observable,
            scaleY: observable,
            handleResize: action,
            enableLandscape: action,
            enablePortrait: action,
            updateScreen: action,
            showVerticalCentered: action,
            all: computed,
            canvasMarginRight: computed,
            winWidth: computed,
            winHeight: computed,
            styles: computed,
            calculateCanvas: computed,
            canvasSize: computed
        })
        this.sdk = sdk;
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        this.renderWidth = 0;
        this.renderHeight = 0;
        this.scaleX = 1.0;
        this.scaleY = 1.0;
        this.offsetX = 0;
        this.offsetY = 0;
        this.verticalCentered = false;
        this.portraitMode = true;
        this.calcWidth = PORTRAIT_CALCULATED_WIDTH;
        this.calcHeight = PORTRAIT_CALCULATED_HEIGHT;
        // this.calcWidth = LANDSCAPE_CALCULATED_WIDTH;
        // this.calcHeight = LANDSCAPE_CALCULATED_HEIGHT;
        this.alignHorizontal = 'center'
        this.alignVertical = 'bottom';

        this.updateScreen()
        this.registerEvents();

        if (!this.initialRender) {
            this.initialRender = setTimeout(() => this.updateScreen(), 1500)
        }
    }

    get winWidth(): number {
        return this.portraitMode ? PORTRAIT_CALCULATED_WIDTH : LANDSCAPE_CALCULATED_WIDTH;
    }

    get winHeight(): number {
        return this.portraitMode ? PORTRAIT_CALCULATED_HEIGHT : LANDSCAPE_CALCULATED_HEIGHT;
    }

    get styles(): any {
        return (options: any) => getCss(options, this.scaleX, this.scaleY)
    }

    showVerticalCentered(state: boolean) {
        //this.verticalCentered = state;
    }

    enableLandscape() {
        this.portraitMode = false;
        this.calcWidth = LANDSCAPE_CALCULATED_WIDTH;
        this.calcHeight = LANDSCAPE_CALCULATED_HEIGHT;
        this.updateScreen()
    }

    enablePortrait() {
        this.portraitMode = true;
        this.calcWidth = PORTRAIT_CALCULATED_WIDTH;
        this.calcHeight = PORTRAIT_CALCULATED_HEIGHT;
        this.updateScreen()
    }

    updateBackground() {
        // document.body.style.backgroundImage = `url(./data/background.jpg)`;
        // document.body.style.backgroundSize = 'cover';
        // document.body.style.backgroundRepeat = 'no-repeat';
        // document.body.style.backgroundColor = '#edc1c0';
        // document.body.style.backgroundColor = '#bf9c00';
    }

    get canvasSize() {
        return {
            width: this.width,
            height: this.height
        }
    }

    get calculateCanvas() {
        return {
            width: `${this.renderWidth}px`,
            height: `${this.renderHeight}px`,
            margin: `${this.offsetY / (this.verticalCentered ? 2 : 1)}px 0 0 ${this.offsetX}px`
        }
    }

    handleResize(event: any): void {
        this.updateScreen(event?.target);
    }

    updateScreen(target: any = undefined): void {

        const useTarget = target || getWindow();
        this.width = this.getInnerWidthTarget(useTarget.innerWidth);
        this.height = this.getInnerHeightTarget(useTarget.innerHeight);

        const ScreenScaleX = this.width / this.calcWidth;
        const ScreenScaleY = this.height / this.calcHeight;

        this.scaleX = 0;
        this.scaleY = 0;

        let UIOffX = 0;
        let UIOffY = 0;

        if (ScreenScaleX !== ScreenScaleY) {

            if (ScreenScaleX < ScreenScaleY) {
                this.scaleX = ScreenScaleX;
                this.scaleY = ScreenScaleX;
            } else if (ScreenScaleY < ScreenScaleX) {
                this.scaleX = ScreenScaleY;
                this.scaleY = ScreenScaleY;
            }

        } else {

            this.scaleX = ScreenScaleX;
            this.scaleY = ScreenScaleY;

        }


        this.renderWidth = Math.round(this.calcWidth * this.scaleX);
        this.renderHeight = Math.round(this.calcHeight * this.scaleY);

        const scX = Math.round(this.calcWidth * this.scaleX);
        const scY = Math.round(this.calcHeight * this.scaleY);

        UIOffX = Math.round(this.getAlignValue(this.width - scX));
        UIOffY = Math.round(this.getVAlignValue(this.height - scY));


        // INFO: Add a left border on iOS
        if (this.isIosMobile) {
            UIOffX = UIOffX + this.getScreenLeftBorder();
            UIOffY = UIOffY + this.getScreenTopBorder();
        }

        this.offsetX = UIOffX;
        this.offsetY = UIOffY;

        // INFO: Extend the height of the canvas / special for wedding wonders
        if (this.offsetY > 0) {
            this.renderHeight += UIOffY;
            this.offsetY = 0;
        }

        this.updateBackground();
    }

    getAlignValue(width: number): number {
        if (width > 0) {
            if (this.alignHorizontal === 'left') return 0;
            if (this.alignHorizontal === 'right') return width;
            return width / 2;
        }
        return 0;
    }

    getVAlignValue(height: number): number {
        if (height > 0) {
            if (this.alignVertical === 'top') return 0;
            if (this.alignVertical === 'bottom') return height;
            return height / 2;
        }
        return 0;
    }

    getInnerWidthTarget(width: number): number {
        if (!this.isIosMobile) {
            return width;
        }
        let leftBorder = 0;
        let rightBorder = 0;
        try {
            leftBorder = this.getScreenLeftBorder();
            rightBorder = this.getScreenRightBorder();
        } catch (e) {
        }
        return width - (leftBorder + rightBorder);
    }

    getInnerHeightTarget(height: number): number {
        if (!this.isIosMobile) {
            return height;
        }

        let totalBorder = 0;

        try {
            totalBorder += this.getScreenTopBorder();
            totalBorder += this.getScreenBottomBorder();
        } catch (e) {
        }

        return height - totalBorder;
    }

    getScreenLeftBorder() {
        if (!this.isIosMobile) {
            return 0;
        }
        let leftBorder = 0;
        try {
            leftBorder = parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sal").replace('px', ''), 10);
        } catch (e) {
            leftBorder = 0;
        }
        return leftBorder;
    }

    getScreenRightBorder() {
        if (!this.isIosMobile) {
            return 0;
        }
        let rightBorder = 0;
        try {
            rightBorder = parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sar").replace('px', ''), 10);
        } catch (e) {
            rightBorder = 0;
        }
        return rightBorder;
    }

    getScreenTopBorder() {
        if (!this.isIosMobile) {
            return 0;
        }
        let topBorder = 0;
        try {
            topBorder = parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sat").replace('px', ''), 10);
        } catch (e) {
            topBorder = 0;
        }
        return topBorder;
    }

    getScreenBottomBorder() {
        if (!this.isIosMobile) {
            return 0;
        }
        let bottomBorder = 0;
        try {
            bottomBorder = parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sab").replace('px', ''), 10);
        } catch (e) {
            bottomBorder = 0;
        }
        return bottomBorder;
    }

    get all() {
        return this.width;
    }

    get isIosMobile() {
        return process.env.REACT_APP_MOBILE_IOS === 'true';
    }

    get canvasMarginRight() {
        return parseInt((document?.getElementById('gamecanvas')?.style?.marginLeft || '0').replace('px', ''), 10);
    }

    registerEvents() {
        const that = this;

        window.addEventListener('resize', (e) => this.handleResize(e));
        window.addEventListener('orientationchange', (e) => this.handleResize(e));

        if (process.env.REACT_APP_MOBILE === "false") {
            document.addEventListener('visibilitychange', () => document.visibilityState === 'visible' ? that.sdk?.onResumeWeb() : null);
        } else {
            // Mobile Listener
            document.addEventListener('pause', (e) => that.sdk?.onPauseMobile(), false);
            document.addEventListener('resume', (e) => that.sdk?.onResumeMobile(), false);
        }

        document.ontouchmove = event => {
            event.preventDefault();
        }
        //@ts-ignore
        // window.addEventListener('touchmove', (event) => event.scale !== 1 ? event.preventDefault() : null, { passive: false });
    }
}