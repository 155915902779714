import {
    observer
} from 'mobx-react-lite';
import { Checkbox, Container, Icon, IconButton, IconSvg, ImagePicker, TextField } from "../../controls"
import WeddingSpaceEditor from '../../../api/space/editor';
import { getMediaSrc } from '../../../helper';


const SettingTextInput = observer(({ sdk, label, value, onInput, store, storeProperty, children }: any) => {
    return <Container
        containerOption={{
            float: 'left',
            width: '100%',
            heightPx: 80,
            margin: [8, 0, 8, 0],
        }}
    >
        <Container
            containerOption={{
                float: 'left',
                widthPx: 244,
                lineHeight: 70,
                color: '#000',
                textAlign: 'right',
                margin: [0, 16, 0, 0],
                fontWeight: 'bold'
            }}
        >
            {label}
        </Container>
        {children ?
            children :
            (
                <TextField
                    id={'settings-owner-a'}
                    inputOption={{
                        widthPx: 320,
                        float: 'left'
                    }}
                    store={store}
                    storeProperty={storeProperty}
                    onInput={(data: any) => onInput ? onInput(data) : null}
                />
            )}
    </Container>
});

const SettingGeneral = observer(({ sdk }: any) => {
    const useSpaceEditor: WeddingSpaceEditor | null = sdk?.space?.editor || null;

    return <Container
        containerOption={{
            widthPx: 620,
            heightPx: 820,
            color: '#000',
            fontSize: 32,
            float: 'left',
            textAlign: 'center',
            margin: [16, 0, 0, 10],
            overflowX: 'hidden',
            overflowY: 'auto'
        }}
    >
        <ImagePicker
            id={'upload-title-image'}
            onHandleUpload={(file: any) => sdk.space.uploadMedia('title', file)}
            multiple={false}
            displayName={'Hintergrundbild'}
            aspectRatio={[16, 9]}
            media={getMediaSrc(sdk.space, 'title', 'test:brautpaar2.jpg')}
            containerOption={{
                float: 'left',
                widthPx: 240,
                heightPx: 240,
                margin: [0, 0, 16, 200],
                position: 'relative',
                borderRadius: 8,
                border: 'solid #000',
                color: '#000'
            }}
        >
            Titelbild
            <Icon
                source={'icon:upload.svg'}
                iconOption={{
                    widthPx: 80,
                    heightPx: 80,
                    position: 'absolute',
                    bottom: 80,
                    right: 80
                }}
            />
        </ImagePicker>
        <SettingTextInput
            sdk={sdk}
            label={'Space Icon'}
        >
            <IconSvg
                id={'space-icon'}
                icon={useSpaceEditor?.icon}
                color={'#000'}
                iconOption={{
                    widthPx: 80,
                    heightPx: 80,
                    float: 'left'
                }}
                onClick={() => sdk.openDropdown([
                    { icon: 'svg>ring', label: 'Ring Version 1' },
                    { icon: 'svg>ring2', label: 'Ring Version 2' },
                    { icon: 'svg>disc', label: 'Icon Disc' }
                ], () => sdk.openSetting())}
            />
        </SettingTextInput>
        <SettingTextInput
            sdk={sdk}
            label={'Ehefrau'}
            store={useSpaceEditor?.owner}
            storeProperty={'ownerA'}
        />
        <SettingTextInput
            sdk={sdk}
            label={'Ehemann'}
            store={useSpaceEditor?.owner}
            storeProperty={'ownerB'}
        />
        <Checkbox
            id={'flagOwner'}
            label={'Eheleute im Titel anzeigen'}
            store={useSpaceEditor}
            storeProperty={'flagOwnerTitle'}
            buttonOption={{
                widthPx: 64,
                heightPx: 64,
                margin: [8, 0, 0, 0]
            }}
            containerOption={{
                float: 'left',
                widthPx: 520,
                margin: [20, 0, 0, 80],
                lineHeight: 80,
                heightPx: 80,
                fontSize: 24,
                color: '#000'
            }}
            labelOption={{
                widthPx: 340,
                lineHeight: 64,
                heightPx: 64,
                margin: [8, 0, 0, 0]
            }}
        />
        {!useSpaceEditor?.flagOwnerTitle ? (
            <SettingTextInput
                sdk={sdk}
                store={useSpaceEditor}
                storeProperty={'title'}
                label={'Titel'}
            />
        ) : null}


    </Container>;
})

export default SettingGeneral;