import {
    observer
} from 'mobx-react-lite';
// import { useEffect } from 'react';
import {
    Container
} from '../../controls/index';

const ComponentTile = observer(({ tile, onAdd }: any) => {
    return <Container
        containerOption={{
            widthPx: 192,
            heightPx: 192,
            bgColor: '#ff00ff',
            float: 'left',
            margin: [8, 0, 0, 12]
        }}
        onClick={() => {
            if(onAdd) {
                onAdd(tile);
            }
        }}
    >
        {tile.label}
    </Container>
});

export default ComponentTile;