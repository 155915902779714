import {
    observer
} from 'mobx-react-lite';
import WeddingSpace from '../api/space';
import LayoutManager from '../api/layoutmanager';
import { useEffect } from 'react';
import MessageDialog from './dialog/MessageDialog';
import DeleteDialog from './dialog/DeleteDialog';
import ImageDialog from './dialog/ImageDialog';
import SettingDialog from './dialog/SettingDialog';
import LoadingSpinner from './LoadingSpinner';
import SpaceView from './view/space';
import AddComponentDialog from './dialog/AddComponentDialog';
import { IconButton } from './controls';
import DropdownDialog from './dialog/DropdownDialog';
import MediaUploadDialog from './dialog/MediaUploadDialog';

const AppCanvas = observer(({ sdk }: any) => {
    const useLayoutManager: LayoutManager | null = sdk?.layoutManager || null;
    const useSpace: WeddingSpace | null = sdk?.space || null;

    useEffect(() => {
        // Update the layoutmanager to fit the margin of the client
        useLayoutManager?.updateScreen(window);
    }, []);

    return <>
        <div
            id={'appcanvas'}
            style={useLayoutManager?.calculateCanvas}
        >
            {/* {useSpace ? <MainView sdk={sdk} />: null} */}
            {useSpace ? <SpaceView sdk={sdk} /> : null}
            {!useSpace ? <>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                    Diese Applikation befindet sich derzeit in Arbeit<br />
                    Bitte schaue später vorbei!
                </div>
            </> : null}
            {useSpace?.addButtonVisible ? (
                <IconButton
                    source={'icon:add.svg'}
                    buttonOption={{
                        position: 'absolute',
                        bottom: 24,
                        right: 24,
                        zIndex: 32,
                        widthPx: 96,
                        heightPx: 96
                    }}
                    onClick={() => sdk.openNewComponentDialog(true)}
                />
            ) : null}
            {useSpace?.djButtonVisible ? (
                <IconButton
                    source={'icon:disc.svg'}
                    buttonOption={{
                        position: 'absolute',
                        bottom: 24,
                        right: 24,
                        zIndex: 32,
                        widthPx: 96,
                        heightPx: 96
                    }}
                    onClick={() => sdk.space.switchView('#dj-overview')}
                />
            ) : null}
            <LoadingSpinner sdk={sdk} />
        </div >
        <MessageDialog sdk={sdk} />
        <DeleteDialog sdk={sdk} />
        <ImageDialog sdk={sdk} />
        <DropdownDialog sdk={sdk} />
        <SettingDialog sdk={sdk} />
        <MediaUploadDialog sdk={sdk} />
        <AddComponentDialog sdk={sdk} />
    </>
});

export default AppCanvas;