import {
    observer
} from 'mobx-react-lite';
// import { translateKey } from '../../utils/helper';
import {
    Container,
    Icon
} from './controls/index';
const LoadingSpinner = observer(({
    sdk,
    label,
    children
}: any) => {
    const { layoutManager } = sdk;

    return sdk.spinner ? <div
        id={'gamespinner'}
    >
        <div
            id={'gamespinner_content'}
            key={1}
            style={layoutManager.calculateCanvas}
        >
            <Container
                id={'spinner_content'}
                containerOption={{
                    widthPx: 312,
                    heightPx: 312,
                    margin: [(layoutManager.winHeight - 312) / 2, 0, 0, (layoutManager.winWidth - 312) / 2]
                }}
            >
                <Container
                    id={'spinner_container'}
                    containerOption={{
                        widthPx: 192,
                        heightPx: 256,
                        margin: [60, 0, 0, 60],
                        float: 'left'
                    }}
                >
                    <Icon
                        id={'spinner-logo'}
                        className={'spinner'}
                        source={'icon:ring.svg'}
                        iconOption={{
                            widthPx: 128,
                            heightPx: 128,
                            float: 'left',
                            margin: [0, 0, 0, 32]
                        }}
                    />
                    <Container
                        id={'spinner_text'}
                        containerOption={{
                            float: 'left',
                            widthPx: 180,
                            lineHeight: 40,
                            fontSize: 20,
                            color: '#fff',
                            textAlign: 'center',
                            fontWeight: 'bold',
                            margin: [16, 0, 0, 0]
                        }}
                    >
                        {sdk.spinner.message}
                    </Container>
                    {sdk.spinner.progress !== null ? (
                        <Container
                            id={'spinner_text'}
                            containerOption={{
                                float: 'left',
                                widthPx: 180,
                                lineHeight: 32,
                                fontSize: 18,
                                color: '#fff',
                                textAlign: 'center',
                                fontWeight: 'bold'
                            }}
                        >
                            {`${sdk.spinner.progress} %`}
                        </Container>
                    ) : null}
                </Container>
                {children ? children : null}
            </Container>
        </div>
    </div> : null
});

export default LoadingSpinner;