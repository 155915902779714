import {
    observer
} from 'mobx-react-lite';
import Container from './Container';
import { getImageSrc } from '../../../helper';
import getSdk from '../../../api';

const ImageComponent = observer(({
    id,
    source,
    errorSource,
    alt,
    imageOptions,
    onClick
}: any) => {
    const sdk = getSdk();
    const useStyles = sdk.layoutManager.styles

    const propsImage = useStyles({
        float: 'left',
        maxWidth: '100%',
        ...(imageOptions || {})
    });

    return <img
        id={id}
        style={propsImage}
        src={getImageSrc(source)}
        alt={alt}
        onClick={() => onClick ? onClick() : null}
        onError={(e) => {
            if(errorSource) {
                // @ts-ignore
                e.target.src = getImageSrc(errorSource);
            }
        }}
    />
});

export default ImageComponent;