import { useEffect } from 'react';
import getSdk, { ApplicationSdk } from './api';
// import GameCanvas from './components/AppCanvas';
import {
  observer
} from 'mobx-react-lite';
// import { getImageSrc } from './helper';
import AppCanvas from './components/AppCanvas';

const App = observer(() => {
  const sdk: ApplicationSdk = getSdk();

  useEffect(() => {
    if (sdk?.space?.palette?.backgroundColor) {
      //@ts-ignore
      document.body.style.backgroundColor = sdk.space.palette.backgroundColor;
      //@ts-ignore
      document.body.style.backgroundImage = null;
    } else {
            //@ts-ignore
            document.body.style.backgroundColor = '#fff';
            //@ts-ignore
            document.body.style.backgroundImage = null;
    }
  }, [sdk.layoutManager, sdk?.space?.palette?.backgroundColor]);
  return (
    <div
      id={'appcontainer'}
    >
      <AppCanvas sdk={sdk} />
    </div>
  );
})

export default App;