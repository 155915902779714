import {
    observer
} from 'mobx-react-lite';
import Container from '../../../controls/components/Container';
import WeddingSpace from '../../../../api/space';
import { TextArea } from '../../../controls';
import WeddingSpaceEditor from '../../../../api/space/editor';

const TextBlock = observer(({
    sdk,
    component,
    children,
    editable,
    inBackground = false
}: any) => {
    const useSpace: WeddingSpace | null = sdk?.space || null;
    const useSpaceEditor:WeddingSpaceEditor | null = useSpace?.editor || null;

    return <Container
        id={`canvas-textblock-${component?.id || ''}`}
        containerOption={{
            float: 'left',
            position: 'relative',
            width: '100%',
            color: useSpace?.palette?.primaryText,
            opacity: inBackground ? 0.2 : 1
        }}
    >
        <Container
            containerOption={{
                widthPx: 660,
                margin: [24, 0, 24, 32],
                textAlign: 'justify',
                fontSize: 30,
                color: useSpace?.palette?.primaryText
            }}
        >
            {editable ? (
                <TextArea
                    value={component?.value || ''}
                    inputOption={{
                        widthPx: 560,
                        heightPx: 128
                    }}
                    onInput={(value: string) => useSpaceEditor?.changeComponentValue(component.id, value)}
                />
            ) : component?.value || '-'}
        </Container>
        {editable ? null : children}
    </Container>;
});

export default TextBlock;