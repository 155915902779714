

import {
    observer
} from 'mobx-react-lite';
import { Container, Title, IconSvg } from '../../../controls';
import WeddingSpace from '../../../../api/space';

const HeadlineTitleBlock = observer(({
    sdk = null
}: any) => {
    const useSpace: WeddingSpace | null = sdk.space;
    const useSplittedTitle: boolean = !!useSpace?.flagOwnerTitle;

    const isSticky = !useSpace?.editMode;

    return <Container
        id={`canvas-head-title-container`}
        containerOption={{
            ...(isSticky ? {
                position: 'sticky'
            } : {}),
            float: 'left',
            width: '100%',
            bgColor: useSpace?.palette?.secondary,
            color: useSpace?.palette?.secondaryText,
            borderSize: 6,
            borderBottom: 'solid #51315c',
            zIndex: 12
        }}
    >
        {useSplittedTitle ? (
            <>
                <Title
                    size={48}
                    className={'fonthead'}
                    titleOptions={{
                        width: undefined,
                        widthPx: 300,
                        textAlign: 'right',
                        fontWeight: 'normal',
                        lineHeight: 96,
                        color: useSpace?.palette?.secondaryText
                    }}
                >
                    {useSpace?.owner?.[0] || ''}
                </Title>
                <Container
                    containerOption={{
                        widthPx: 96,
                        heightPx: 96,
                        float: 'left'
                    }}
                >
                    <IconSvg
                        id={'ring-image'}
                        className={'pulsate-fwd'}
                        icon={useSpace?.icon || ''}
                        iconOption={{ margin: [16, 0, 0, 20] }}
                        color={useSpace?.palette?.secondaryText || '#fff'}
                    />
                    {/* <Icon id={'ring-image'} className={'pulsate-fwd'} source={'icon:ring.svg'} iconOption={{ margin: [16, 0, 0, 20] }} /> */}
                </Container>
                <Title
                    size={48}
                    className={'fonthead'}
                    titleOptions={{
                        width: undefined,
                        widthPx: 300,
                        textAlign: 'left',
                        fontWeight: 'normal',
                        lineHeight: 96,
                        color: useSpace?.palette?.secondaryText
                    }}
                >
                    {useSpace?.owner?.[1] || ''}
                </Title>
            </>
        ) : null}
        {!useSplittedTitle ? (
            <Title
                size={48}
                className={'fonthead'}
                titleOptions={{
                    width: '100%',
                    fontWeight: 'normal',
                    lineHeight: 96,
                    textAlign: 'center',
                    color: useSpace?.palette?.secondaryText
                }}
            >
                {useSpace?.title || ''}
            </Title>
        ) : null}
    </Container>;
});

export default HeadlineTitleBlock;