import {
    observer
} from 'mobx-react-lite';
import getSdk from '../../../api'
import Cropper from 'react-easy-crop'
import { toJS } from 'mobx';

const CropperComponent = observer(({
    containerOption,
    store
}: any) => {
    const sdk = getSdk();
    const useStyles = sdk.layoutManager.styles

    const propsCropperContainer = useStyles({
        widthPx: 240,
        heightPx: 240,
        ...containerOption
    });

    return <Cropper
        image={store.original}
        crop={store.crop}
        zoom={store.zoom}
        aspect={store.aspectRatio}
        showGrid={true}
        zoomWithScroll={false}
        onCropChange={(c: any) => store.updateValue('crop', c)}
        onZoomChange={(c: any) => store.updateValue('zoom', c)}
        onCropComplete={(a: any, b: any) => store.updateValue('croppedAreaPixels', b)}
        style={{
            containerStyle: propsCropperContainer
        }}
    />
});

export default CropperComponent;