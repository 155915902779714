import { action, computed, makeObservable, observable } from "mobx";
import WeddingGallery from "./gallery";
import WhishList from "./whishlist";
import { adjust, changeStylesheetProperties } from "../../helper";
import { ApplicationSdk } from "..";
import WeddingSpaceEditor from "./editor";

const SPACE_VIEWS = [
    '#dj-overview'
]

const SPACE_TYPE = {
    WEDDING: 'wedding',
    DJ: 'dj'
}
class SpacePalette {
    public colors: any = {
        background: '#edc1c0',
        primary: '#fff',
        secondary: '#855495',
        tertiary: '#fff',
        primaryText: '#000',
        secondaryText: '#000'
    }

    constructor() {
        makeObservable(this, {
            colors: observable,
            updateColors: action,
            backgroundColor: computed,
            primary: computed,
            tertiary: computed,
            tertiaryText: computed,
            tertiaryTextDark: computed,
            primaryDark: computed,
            primaryText: computed,
            secondary: computed,
            secondaryText: computed,
            secondaryLight: computed,
            secondaryDark: computed
        });
    }

    public updateColors(colors: any): void {
        this.colors = colors;

        // Use this to update all client colors
        changeStylesheetProperties({
            a: {
                color: this.tertiaryText
            },
            'a:hover': {
                color: this.primaryText
            },
            '.gameinput::placeholder': {
                color: this.secondaryText
            },
            '::-webkit-scrollbar-track': {
                background: this.primary
            },
            '::-webkit-scrollbar-thumb': {
                background: this.secondary
            },
            '::-webkit-scrollbar-thumb:hover': {
                background: this.tertiary
            }
        })
    }

    get backgroundColor(): string {
        return this.colors.background;
    }

    get primary(): string {
        return this.colors.primary;
    }

    get primaryText(): string {
        return this.colors.primaryText;
    }

    get secondary(): string {
        return this.colors.secondary;
    }

    get tertiary(): string {
        return this.colors.tertiary;
    }

    get tertiaryText(): string {
        return this.tertiary;
    }

    get tertiaryTextDark(): string {
        return adjust(this.tertiary, -80);
    }

    get secondaryText(): string {
        return this.colors.secondaryText;
    }

    get secondaryLight(): string {
        return adjust(this.colors.secondary, 50);
    }

    get secondaryDark(): string {
        return adjust(this.colors.secondary, -50);
    }

    get primaryDark(): string {
        return adjust(this.colors.primary, -50);
    }
}

class WeddingSpace {
    private sdk: ApplicationSdk;

    public inited: boolean;
    public name: string;
    public id: number | null = null;

    public type: string;
    public hash: string;
    public uuid: string | null;

    public view: string | null;

    public title: string;
    public owner: Array<string>;
    public icon: string;

    public flagOwnerTitle: number = 0;

    public gallery: WeddingGallery | null = null;
    public whishList: WhishList | null = null;
    public layout: any[] = [];
    public colors: any = {};
    public palette: SpacePalette | null = null;
    public role: string = '';
    public media: any = {};

    public admin: boolean = false;
    public dj: boolean = false;
    public editor: WeddingSpaceEditor | null = null;

    public testColor: string = '';

    constructor(sdk: ApplicationSdk, spaceType: string, spaceHash: string, spaceName: string) {
        makeObservable(this, {
            load: action,
            update: action,
            destroy: action,
            switchView: action,
            loadFromCache: action,
            syncPaletteWithEditor: action,
            title: observable,
            icon: observable,
            media: observable,
            owner: observable,
            flagOwnerTitle: observable,
            editor: observable,
            view: observable,
            inited: observable,
            syncEditor: action,
            updateMedia: action,
            updateTestColor: action,
            testColor: observable,
            name: observable,
            admin: observable,
            dj: observable,
            id: observable,
            gallery: observable,
            palette: observable,
            colors: observable,
            layout: observable,
            enableDeejay: action,
            hasEditorUpdate: computed,
            isDeejay: computed,
            editMode: computed,
            addButtonVisible: computed,
            djButtonVisible: computed,
            blocks: computed,
            hashSpace: computed,
            role: observable
        });

        this.sdk = sdk;
        this.inited = false;
        this.name = spaceName;
        this.hash = spaceHash;
        this.uuid = null;
        this.type = spaceType;
        this.id = 1; // Todo: this is the id of the testspace
        this.layout = [];
        this.gallery = new WeddingGallery(this.id);
        this.whishList = new WhishList(spaceHash, spaceName);
        this.palette = new SpacePalette();
        this.admin = false;
        this.dj = false;
        this.title = '';
        this.icon = '';
        this.owner = [];
        this.flagOwnerTitle = 0;
        this.view = null;

        this.loadFromCache();
    }


    public switchView(nextView: string | null): void {
        if(nextView === this.view) {
            return;
        }

        if(nextView === null) {
            this.view = null;
            window.history.pushState('', document.title, window.location.pathname + window.location.search);
            this.sdk.clearAllIntervals();
        } else if (SPACE_VIEWS.includes(nextView || '')) {
            if (window?.location?.hash !== nextView) {
                window.history.pushState({}, '', `${nextView}`);
                this.view = nextView;
            }
            if(this.view === '#dj-overview') {
                this.enableDeejay();

            }
        }
    }

    public syncPaletteWithEditor(): void {
        this.palette?.updateColors(this.editor?.colors)
    }

    public updateTestColor(c: string) {
        this.testColor = c;
    }

    public async load(force: boolean = false): Promise<any> {
        const dataRequest: any = await this.sdk.fetchGetApi(`space/${this.hash}/${this.name}/init`);

        if (dataRequest?.status === 200) {
            const editorHasUpdate: boolean = this.hasEditorUpdate;

            this.update(dataRequest.json);
            this.cacheSpace();

            // Only synchronize when the editor has no update
            if (!editorHasUpdate || force === true) {
                this.syncEditor();
            }
        } else if (dataRequest?.status === 403) {
            return dataRequest?.json;
        }
        return true;
    }

    public async save(): Promise<void> {
        const dataRequest: any = await this.sdk.fetchPutApi(`space/${this.hash}/${this.name}/update`, {
            data: this.editor ? this.editor.data : {}
        });

        if (dataRequest?.status === 200) {
            this.update(dataRequest.json, true);
            this.cacheSpace();
        }
    }

    public async uploadMedia(type: string, file: any): Promise<void> {
        const formData = new FormData();

        // formData.append('File', this.blobToFile(file, "avatar.jpg"));
        formData.append('File', file.lengt ? file[0] : file);
        formData.append('spaceName', this.name || '');
        formData.append('spaceHash', this.hash || '');
        formData.append('imageType', type);

        const mediaUpload: any = await this.sdk.fetchPostApi(`${process.env.REACT_APP_MEDIA_URL}upload.php`, formData, {
            multiPart: true,
            timeout: 30000
        });

        if (mediaUpload?.status === 200 || mediaUpload?.status === 201) {
            this.updateMedia(mediaUpload.json);
        }
    }

    public updateMedia(media: any): void {
        if (Array.isArray(media)) {
            media.forEach((m: any) => {
                this.media[m.imageType] = {
                    fileSize: m.fileSize,
                    fileType: m.fileType
                };
            })
        } else {
            this.media[media.imageType] = {
                fileSize: media.fileSize,
                fileType: media.fileType
            };
        }
    }

    public update(data: any, updateEndpoint: boolean = false): void {
        this.layout = data?.layout || data?.config?.layout || [];
        this.colors = data?.colors || data?.config?.colors || [];

        if (data?.config?.colors) {
            this.palette?.updateColors(data?.config?.colors)
        }
        
        this.owner = data?.config?.owner || [];
        this.title = data?.config?.title || '';
        this.icon = data?.config?.icon || '';
        this.flagOwnerTitle = data?.config?.flags?.ownerTitle || 0;
        
        this.uuid = data?.uuid || null;

        if(!updateEndpoint) {
            this.admin = data?.admin || false;
            this.dj = data?.dj || false;
            this.updateMedia(data?.media || []);
        }
    }

    public loadFromCache(): void {
        console.log('LOAD FROM CACHE')
        const cacheData = this.sdk.loadFromStorage('space');
        if (cacheData?.[this.spaceHash]) {
            this.update(cacheData[this.spaceHash]);
            this.syncEditor();
        }
    }

    public cacheSpace(): void {
        this.sdk.saveInStorage('space', {
            [this.spaceHash]: {
                layout: this.layout,
                admin: this.admin
            }
        })
    }

    public destroy(): void {
    }

    get hasEditorUpdate(): boolean {
        if (!this.editor) {
            return false
        }
        return this.editor.hashSpace !== this.hashSpace;
    }

    get hashSpace() {
        return JSON.stringify({
            layout: this.layout,
            colors: this.colors,
            owner: this.owner,
            flagOwnerTitle: this.flagOwnerTitle,
            title: this.title,
            icon: this.icon
        })
    }

    public syncEditor(): void {
        // sync the editor only, if there is no change
        if (this.admin) {
            if (!this.editor) {
                this.editor = new WeddingSpaceEditor();
            }
            this.editor.update(this);
        } else {
            console.log('NO ADMIN!')
        }
    }

    public enableDeejay(): void {
        this.whishList?.enableAutoUpdate(this.sdk);
    }

    get isDeejay(): boolean {
        return this.role === 'deejay';
    }

    get blocks(): Array<any> {
        return this.editor?.layout || this.layout;
    }

    get spaceHash(): string {
        return encodeURIComponent(`${this.hash}/${this.name}`)
    }

    get editMode(): boolean {
        return !!this.editor;
    }

    get addButtonVisible(): boolean {
        return this.admin && !!this.editor && !this.editor.activeComponent;
    }

    get djButtonVisible(): boolean {
        return this.dj && this.view !== '#dj-overview';
    }
}

const getSpaceType = (input: string): string | null => {
    if (input === 'wedding') {
        return SPACE_TYPE.WEDDING;
    }

    if (input === 'wedding') {
        return SPACE_TYPE.DJ;
    }
    return null;
}

export const createSpaceFromPath = (sdk: ApplicationSdk, path: string): WeddingSpace | null => {
    if (path) {
        const readPathInfo: Array<string> = path.split('/').slice(-3);
        if (readPathInfo?.length === 3) {
            const useSpaceType: string | null = getSpaceType(readPathInfo[0]);
            const useSpaceHash: string = readPathInfo[1];
            const useSpaceName: string = readPathInfo[2];

            // Validate space type
            if (useSpaceType === null) {
                return null;
            }

            // uri hash should have at least 10 characters
            if (useSpaceHash?.length < 4 || useSpaceHash?.length > 16) {
                return null;
            }

            // At least 3 character space
            if ((useSpaceName?.length || 0) < 3) {
                return null;
            }

            if (readPathInfo[0] === 'wedding') {
                return new WeddingSpace(
                    sdk,
                    useSpaceType,
                    useSpaceHash,
                    useSpaceName
                );
            }
        }
    }

    return null;
}

export default WeddingSpace;