import {
    observer
} from 'mobx-react-lite';
import Container from './Container';
import TextField from './TextField';

const Title = observer(({
    id,
    children,
    titleOptions,
    inputOptions,
    className,
    size = 22,
    value,
    editable = false,
    onChange
}: any) => {
    return <Container
        id={id}
        className={className}
        containerOption={{
            float: 'left',
            width: '100%',
            lineHeight: 80,
            textAlign: 'center',
            fontSize: size,
            fontWeight: 'bold',
            ...(titleOptions || {})
        }}
    >
        {editable ? (
            <TextField
                id={id}
                value={value}
                inputOption={{
                    float: 'left',
                    width: '100%',
                    lineHeight: 80,
                    textAlign: 'center',
                    fontSize: size,
                    fontWeight: 'bold',
                    ...(inputOptions || {})
                }}
                onInput={(text: string) => onChange(text)}
            />
        ) : value || ''}
        {children ? children : null}
    </Container>;
});

export default Title;