import {
    observer
} from 'mobx-react-lite';
import Container from './Container';
import { getImageSrc, readFileToNormalize } from '../../../helper';
import getSdk from '../../../api';

const ImagePicker = observer(({
    id,
    containerOption,
    onHandleUpload,
    children,
    multiple,
    media,
    aspectRatio = [16, 9]
}: any) => {
    const sdk = getSdk();
    return <>
        <input
            type={'file'}
            name={'file'}
            className={'inputfile'}
            style={{
                display: 'none'
            }}
            id={id}
            multiple={multiple || false}
            onChange={async (event: any) => {
                if (event.target.files && event.target.files.length > 0) {
                    const file = event.target.files[0]
                    const newFile = await readFileToNormalize(file)
                    if(newFile) {
                        sdk.openMediaUploader(newFile, aspectRatio, (media: any) => onHandleUpload ? onHandleUpload(media) : null)
                    }
                }
            }}
        />
        <label
            htmlFor={id}
        >
            <Container
                id={id}
                containerOption={{
                    float: 'left',
                    ...(containerOption || {}),
                    ...(media ? {
                        bgImage: `url(${getImageSrc(media)})`,
                        bgRepeat: 'no-repeat',
                        bgImageSize: 'cover'
                    } : {})
                }}
            >
                {children ? children : null}
            </Container>
        </label>
    </>
});

export default ImagePicker;