import {
    observer
} from 'mobx-react-lite';
import {
    ActionButton,
    Container,
    IconButton
} from '../../controls/index';
import SettingColor from './SettingColor';
import SettingGeneral from './SettingGeneral';

const dialogSettingWidth = 640;
const dialogSettingHeight = 936;

const SettingDialog = observer(({ sdk }: any) => {
    const {
        layoutManager
    } = sdk;

    const dialog = sdk.dialog?.type === 'setting' ? sdk.dialog : null;

    return dialog ? <div
        id={'gamedialog'}
        onClick={() => sdk.closeDialog(true)}
        style={{}}
    >
        <div
            id={'gamedialog_string_wrapper'}
            key={1}
            style={layoutManager.calculateCanvas}
        >
            <Container
                id={'gamedialog_string_content'}
                containerOption={{
                    widthPx: dialogSettingWidth,
                    heightPx: dialogSettingHeight,
                    borderRadius: 16,
                    // border: 'solid #fff',
                    // borderSize: 4,
                    zIndex: 60,
                    bgColor: '#fff',
                    margin: [(1280 - dialogSettingHeight) / 2, 0, 0, (720 - dialogSettingWidth) / 2]
                }}
                onClick={(event: any) => {
                    event.stopPropagation();
                }}
            >
                <Container
                    id={'gamedialog_string_message'}
                    containerOption={{
                        position: 'relative',
                        width: '100%',
                        heightPx: 80,
                        lineHeight: 80,
                        borderCorner: [16, 16, 0, 0],
                        color: '#fff',
                        bgColor: '#855495',
                        fontSize: 32,
                        fontWeight: 'bold',
                        textAlign: 'center',
                        margin: [0, 0, 0, 0]
                    }}
                >
                    Einstellungen ( {sdk.dialog.view === 'general' ? 'Allgemein' : ''}{sdk.dialog.view === 'color' ? 'Farben' : ''} )
                    <IconButton
                        id={'close-dialog'}
                        source={'icon:cancel.svg'}
                        buttonOption={{
                            widthPx: 64,
                            heightPx: 64,
                            position: 'absolute',
                            right: 8,
                            top: 8
                        }}
                        onClick={() => sdk.closeDialog(true)}
                    />
                </Container>
                {sdk.dialog.view === 'general' ? <SettingGeneral sdk={sdk} /> : null}
                {sdk.dialog.view === 'color' ? <SettingColor sdk={sdk} /> : null}
            </Container>
            <ActionButton
                id={'btn-colors'}
                icon={'icon:setting.svg'}
                buttonOption={{
                    widthPx: 280,
                    margin: [16, 0, 0, 48],
                    padding: [0, 0, 0, 0],
                    lineHeight: 48,
                    outlineBorderSize: 4,
                    outlineBorder: '#5b3368'
                }}
                iconOption={{
                    widthPx: 48,
                    heightPx: 48,
                    margin: [0, 0, 0, 0]
                }}
                enableDelay={200}
                actionColor={'#855495'}
                onClick={(event: any) => {
                    event.stopPropagation();
                    sdk.openSetting('general');
                }}
            >
                Allgemein
            </ActionButton>
            <ActionButton
                id={'btn-colors'}
                icon={'icon:palette.svg'}
                buttonOption={{
                    widthPx: 280,
                    margin: [16, 0, 0, 48],
                    padding: [0, 0, 0, 0],
                    lineHeight: 48,
                    outlineBorderSize: 4,
                    outlineBorder: '#5b3368'
                }}
                iconOption={{
                    widthPx: 48,
                    heightPx: 48,
                    margin: [0, 0, 0, 0]
                }}
                enableDelay={200}
                actionColor={'#855495'}
                onClick={(event: any) => {
                    event.stopPropagation();
                    sdk.openSetting('color');
                }}
            >
                Farben
            </ActionButton>
        </div>
    </div > : null;
});

export default SettingDialog;