import { HexColorPicker as HexPicker } from 'react-colorful';

import {
    observer
} from 'mobx-react-lite';
import getSdk from '../../../api'

const HexColorPicker = observer(({
    id,
    pickerOption = {},
    store = undefined,
    storeProperty = undefined,
    value,
    onChange
}: any) => {
    const sdk = getSdk();
    const useStyles = sdk.layoutManager.styles
    let useValue = value || '#ffffff';

    if (store && storeProperty) {
        if (Array.isArray(storeProperty)) {
            useValue = store[storeProperty[0]][storeProperty[1]];
        } else {
            useValue = store[storeProperty];
        }
    }

    const propsPicker = useStyles({
        ...(pickerOption || {})
    });

    return <HexPicker
        id={id}
        color={useValue}
        style={propsPicker}
        onChange={(color) => {
            // Update the statepath
            if (store && storeProperty) {
                if (store?.updateValue) {
                    store.updateValue(storeProperty, color)
                }
            }
            if (onChange) {
                onChange(color)
            }
        }}
    />
});

export default HexColorPicker;