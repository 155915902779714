import {
    observer
} from 'mobx-react-lite';
import getSdk from '../../../api'
import { getImageSrc } from '../../../helper';

const Icon = observer(({
    id,
    iconOption,
    className,
    width,
    height,
    source,
    label = null,
    onClick
}: any) => {
    const sdk = getSdk();
    const useStyles = sdk.layoutManager.styles

    const propsIcon = useStyles({
        bgImage: `url(${getImageSrc(source)})`,
        bgImageSize: 'cover',
        bgRepeat: 'no-repeat',
        widthPx: width || 64,
        heightPx: height || 64,
        border: 'none',
        ...(label ? {
            lineHeight: height || 64,
            textAlign: 'center'
        } : {}),
        ...(iconOption || {})
    });
   
    return <div
        id={id}
        className={className}
        style={propsIcon}
        onClick={(event: any) => onClick ? onClick(event) : null}
    >{label}</div>
});

export default Icon;