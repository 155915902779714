import {
    observer
} from 'mobx-react-lite';
import {
    ActionButton,
    Container,
    IconSvg,
    IconButton
} from '../../controls/index';


const DropdownDialog = observer(({ sdk }: any) => {
    const {
        layoutManager
    } = sdk;

    const dialog = sdk.dialog?.type === 'dropdown' ? sdk.dialog : null;
    const items = dialog?.items || [];
    const dialogWidth: number = 640;
    const dialogHeight: number = 640;

    return dialog ? <div
        id={'gamedialog'}
        onClick={() => {
            sdk.closeDialog(true);
            if (dialog?.onClose) dialog.onClose();
        }}
        style={{}}
    >
        <div
            id={'gamedialog_string_wrapper'}
            key={1}
            style={layoutManager.calculateCanvas}
        >
            <Container
                id={'gamedialog_string_content'}
                containerOption={{
                    widthPx: dialogWidth,
                    heightPx: dialogHeight,
                    borderRadius: 16,
                    zIndex: 60,
                    bgColor: '#fff',
                    margin: [(1280 - dialogHeight) / 2, 0, 0, (720 - dialogWidth) / 2]
                }}
                onClick={(event: any) => {
                    event.stopPropagation();
                }}
            >
                <Container
                    id={'gamedialog_string_message'}
                    containerOption={{
                        position: 'relative',
                        width: '100%',
                        heightPx: 80,
                        lineHeight: 80,
                        borderCorner: [16, 16, 0, 0],
                        color: '#fff',
                        bgColor: '#855495',
                        fontSize: 32,
                        fontWeight: 'bold',
                        textAlign: 'center',
                        margin: [0, 0, 0, 0]
                    }}
                >
                    Dropdown
                    <IconButton
                        id={'close-dialog'}
                        source={'icon:cancel.svg'}
                        buttonOption={{
                            widthPx: 64,
                            heightPx: 64,
                            position: 'absolute',
                            right: 8,
                            top: 8
                        }}
                        onClick={() => {
                            sdk.closeDialog(true)
                            if (dialog?.onClose) dialog.onClose();
                        }}
                    />
                </Container>
                <Container
                    containerOption={{
                        overflowY: 'auto',
                        widthPx: 620,
                        heightPx: 540,
                        margin: [10, 0, 0, 10]
                    }}
                >
                    {items.map((item: any, index: number) => {
                        return <Container
                            key={`dropdown-${index}`}
                            containerOption={{
                                color: '#000',
                                width: '100%',
                                lineHeight: 96,
                                fontSize: 32,
                                float: 'left'
                            }}
                            onClick={() => {
                                if (sdk.space?.editor) {
                                    sdk.space.editor.updateValue('icon', item.icon);
                                    if(dialog?.onClose) dialog.onClose();
                                }
                            }}
                        >
                            <IconSvg
                                icon={item.icon}
                                iconOption={{
                                    widthPx: 80,
                                    heightPx: 80,
                                    margin: [8, 16, 0, 8],
                                    float: 'left'
                                }}
                                color={'#000'}
                            />
                            {item.label}
                        </Container>
                    })}
                </Container>
            </Container>
            <ActionButton
                id={'btn-close'}
                icon={'icon:cancel.svg'}
                buttonOption={{
                    widthPx: 280,
                    margin: [16, 0, 0, 48],
                    padding: [0, 0, 0, 0],
                    lineHeight: 48,
                    outlineBorderSize: 4,
                    outlineBorder: '#5b3368'
                }}
                iconOption={{
                    widthPx: 48,
                    heightPx: 48,
                    margin: [0, 0, 0, 0]
                }}
                enableDelay={200}
                actionColor={'#855495'}
                onClick={(event: any) => {
                    sdk.closeDialog(true)
                    if (dialog?.onClose) dialog.onClose();
                }}
            >
                Schließen
            </ActionButton>
        </div>
    </div > : null;
});

export default DropdownDialog;