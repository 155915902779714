import { observer } from 'mobx-react-lite';
import { ImageComponent, Container, Title } from '../../controls';

const SwitchBlock = observer(({
    whishList
}: any) => {
    return <Container
        id={'dj-switcher'}
        containerOption={{
            float: 'left',
            width: '100%',
            bgColor: '#c399d1'
        }}
        onClick={() => whishList.switchList()}
    >
        <ImageComponent
            id={`switcher-image`}
            source={whishList.viewAll ? 'icon:switchon.svg' : 'icon:switchoff.svg'}
            imageOptions={{
                widthPx: 48,
                float: 'left',
                margin: [16, 16, 16, 60]
            }}
        />
        <Title titleOptions={{
            color: '#fff',
            width: undefined,
            widthPx: 420
        }} size={32}>Gespielte Songs</Title>
    </Container>;

});

export default SwitchBlock;