import {
    observer
} from 'mobx-react-lite';
// import { useEffect } from 'react';
import {
    Container,
    IconButton
} from '../controls/index';

const MessageDialog = observer(({ sdk }: any) => {
    const {
        layoutManager
    } = sdk;

    const dialog = sdk.dialog?.type === 'dialog' ? sdk.dialog : null;

    const dialogTitle = dialog?.title || '';
    const dialogMessage = dialog?.message || '';
    const dialogYes = dialog?.cbYes || null;

    return dialog ? <div
        id={'gamedialog'}
        onClick={() => null}
        style={{}}
    >
        <div
            id={'gamedialog_string_wrapper'}
            key={1}
            style={layoutManager.calculateCanvas}
        >
            <Container
                id={'gamedialog_string_content'}
                containerOption={{
                    widthPx: 640,
                    heightPx: 362,
                    borderRadius: 16,
                    // border: 'solid #fff',
                    // borderSize: 4,
                    zIndex: 60,
                    bgColor: '#fff',
                    margin: [(1280 - 362) / 2, 0, 0, (720 - 640) / 2]
                }}
            >
                <Container
                    id={'gamedialog_string_message'}
                    containerOption={{
                        width: '100%',
                        heightPx: 64,
                        lineHeight: 64,
                        color: '#fff',
                        bgColor: '#855495',
                        fontSize: 32,
                        fontWeight: 'bold',
                        textAlign: 'center',
                        margin: [0, 0, 0, 0],
                        role: 'alert'
                    }}
                >
                    {dialogTitle}
                </Container>
                <Container
                    containerOption={{
                        widthPx: 620,
                        heightPx: 32,
                        color: '#000',
                        fontSize: 32,
                        float: 'left',
                        // fontWeight: 'bold',
                        textAlign: 'center',
                        margin: [32, 0, 32, 10],
                        role: 'alert'
                    }}
                >
                    {dialogMessage}
                </Container>
                <IconButton
                    source={'icon:accept.svg'}
                    buttonOption={{
                        widthPx: 96,
                        heightPx: 96,
                        float: 'right',
                        margin: [64, 32, 0, 0]
                    }}
                    onClick={() => {
                        if (dialogYes) {
                            dialogYes();
                        }
                        sdk.closeDialog();
                    }}
                />

            </Container>

        </div>
    </div > : null;
});

export default MessageDialog;