

import {
    observer
} from 'mobx-react-lite';
import { Title } from '../../../controls';
import WeddingSpace from '../../../../api/space';
import WeddingSpaceEditor from '../../../../api/space/editor';

const TitleBlock = observer(({
    sdk = null,
    editable,
    component,
    inBackground=false
}: any) => {
    const useSpace: WeddingSpace | null = sdk?.space || null;
    const useSpaceEditor: WeddingSpaceEditor | null = useSpace?.editor || null;
    const title: any = component?.title;

    return title?.active ? (
        <Title
            titleOptions={{
                fontSize: 32,
                bgColor: useSpace?.palette?.secondaryLight,
                color: useSpace?.palette?.secondaryText,
                opacity: inBackground ? 0.2 : 1
            }}
            inputOptions={{
                fontSize: 32
            }}
            editable={editable}
            value={title.value}
            onChange={(text: string) => useSpaceEditor?.changeComponentTitle(component.id, text)}
        />
    ) : null;
});

export default TitleBlock;