import {
    observer
} from 'mobx-react-lite';
import Container from '../../../controls/components/Container';
import Title from '../../../controls/components/Title';
import ImageComponent from '../../../controls/components/Image';
import { getImageSrc } from '../../../../helper';

const ImageBlock = observer(({
    component,
    fullWidth = true,
    children,
    editable,
    inBackground = false
}: any) => {
    return <Container
        id={`canvas-imageblock-${component?.id || ''}`}
        containerOption={{
            position: 'relative',
            float: 'left',
            width: '100%',
            bgColor: '#fff',
            opacity: inBackground ? 0.2 : 1
        }}
    // onClick={() => onClick ? onClick() : null}
    >
        <ImageComponent
            id={`canvas-imageblock-${component?.id || ''}-image`}
            source={getImageSrc(component?.value)}
            imageOptions={fullWidth ? {
                width: '100%',
                bgImageSize: 'contain'
            } : {
                widthPx: 600,
                margin: [16, 0, 16, 60]
            }}
        />
        {editable ? null : children}
    </Container>;
});

export default ImageBlock;