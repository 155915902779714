import {
    observer
} from 'mobx-react-lite';
import { Container, HexColorPicker } from "../../controls"

const SettingColorPicker = observer(({ sdk, label, property }: any) => {
    return <Container
        containerOption={{
            width: '100%',
            heightPx: 96,
            margin: [8, 0, 0, 0],
            float: 'left'
        }}
    >
        <Container
            onClick={() => sdk.space.editor.selectColorPicker(property)}
            containerOption={{
                widthPx: 80,
                heightPx: 80,
                borderRadius: 2,
                border: 'solid #000',
                bgColor: sdk.space.editor[property],
                float: 'left'
            }}
        />
        <Container
            containerOption={{
                color: '#000',
                fontWeight: 'bold',
                lineHeight: 96,
                float: 'left',
                margin: [0, 0, 0, 16]
            }}
        >
            {label}
        </Container>
    </Container>
})

const SettingColor = observer(({ sdk }: any) => {
    return <Container
        containerOption={{
            widthPx: 620,
            heightPx: 820,
            color: '#000',
            fontSize: 32,
            float: 'left',
            textAlign: 'center',
            margin: [16, 0, 0, 10],
            overflowX: 'hidden',
            overflowY: 'auto'
        }}
    >

        <HexColorPicker
            id={'settings-colorpicker'}
            store={sdk.space.editor}
            storeProperty={sdk.space.editor.selectedColor}
            pickerOption={{
                widthPx: 320,
                heightPx: 320,
                margin: [0, 0, 0, 140]
            }}
            onChange={() => sdk.space.syncPaletteWithEditor()}
        />
        <SettingColorPicker sdk={sdk} property={'colorBackground'} label={'Background'} />
        <SettingColorPicker sdk={sdk} property={'colorPrimary'} label={'Primary Color'} />
        <SettingColorPicker sdk={sdk} property={'colorSecondary'} label={'Secondary Color'} />
        <SettingColorPicker sdk={sdk} property={'colorTertiary'} label={'Tertiary Color'} />
        <SettingColorPicker sdk={sdk} property={'colorPrimaryText'} label={'Primary Text'} />
        <SettingColorPicker sdk={sdk} property={'colorSecondaryText'} label={'Secondary Text'} />
    </Container>;
})

export default SettingColor;