import {
    observer
} from 'mobx-react-lite';
// import { useEffect } from 'react';
import {
    Container,
    IconButton
} from '../../controls/index';
import ComponentTile from './ComponentTile';
import SPACE_TILES from '../../../constants/tiles';
const dialogAddComponentWidth = 640;
const dialogAddComponentHeight = 640;

const AddComponentDialog = observer(({ sdk }: any) => {
    const {
        layoutManager
    } = sdk;

    const dialog = sdk.dialog?.type === 'newcomponent' ? sdk.dialog : null;
    const dialogAddBefore = sdk.dialog?.addBefore ? sdk.dialog.addBefore : null;

    return dialog ? <div
        id={'gamedialog'}
        onClick={() => sdk.closeDialog()}
        style={{}}
    >
        <div
            id={'gamedialog_string_wrapper'}
            key={1}
            style={layoutManager.calculateCanvas}
            onClick={(event) => {
                event.stopPropagation();
            }}
        >
            <Container
                id={'gamedialog_string_content'}
                containerOption={{
                    widthPx: dialogAddComponentWidth,
                    heightPx: dialogAddComponentHeight,
                    borderRadius: 16,
                    // border: 'solid #fff',
                    // borderSize: 4,
                    zIndex: 60,
                    bgColor: '#fff',
                    margin: [(1280 - dialogAddComponentHeight) / 2, 0, 0, (720 - dialogAddComponentWidth) / 2]
                }}
            >
                <Container
                    id={'gamedialog_string_message'}
                    containerOption={{
                        width: '100%',
                        heightPx: 64,
                        lineHeight: 64,
                        color: '#fff',
                        bgColor: '#855495',
                        fontSize: 32,
                        fontWeight: 'bold',
                        textAlign: 'center',
                        margin: [0, 0, 0, 0]
                    }}
                >
                    Add new component
                </Container>
                <Container
                    containerOption={{
                        widthPx: 620,
                        heightPx: 520,
                        color: '#000',
                        fontSize: 32,
                        float: 'left',
                        textAlign: 'center',
                        margin: [32, 0, 0, 10],
                        overflowX: 'hidden',
                        overflowY: 'auto'
                    }}
                >
                    {SPACE_TILES.map((tile: any, index: number) => <ComponentTile
                        key={`tile-${index}`}
                        sdk={sdk}
                        tile={tile}
                        onAdd={async () => {
                            const res = await sdk.space?.editor?.addComponent(dialogAddBefore, tile);

                            if(res === true) {
                                sdk.closeDialog(); 
                                console.log('COSE')
                            }
                        }}
                    />)}
                </Container>
            </Container>

        </div>
    </div > : null;
});

export default AddComponentDialog;