import {
    observer
} from 'mobx-react-lite';
import getSdk from '../../../api'

// export const ContainerType = {
//     Default: {
//         gfx: './data/container/container1.png',
//         color: '#000',
//         width: 384,
//         height: 80
//     }
// }

const Container = observer(({
    id,
    containerOption,
    children,
    onClick,
    width,
    height,
    className,
    containerImage
    // containerType = ContainerType.Default
}: any) => {
    const sdk = getSdk();
    const useStyles = sdk.layoutManager.styles

    const propsContainer = useStyles({
        ...(containerImage ? {
            bgImage: `url(./data/container/${containerImage}.png)`,
            bgImageSize: 'contain',
            bgRepeat: 'no-repeat',
        }: {}),
        widthPx: width,
        heightPx: height,
        color: '#fff',
        ...(containerOption || {})
    })

    // const bindDoubleTap = useDoubleTap(onDoubleTap ? () => {
    //     onDoubleTap();
    //   } : null)

    return <div
        id={id ? `${id}-container` : undefined}
        style={propsContainer}
        className={className}
        onClick={(event: any) => onClick ? onClick(event) : null}
    // ref={containerRef}
    // draggable={draggable}
    // onDragStart={onDragStart}
    // onDragEnd={onDragEnd}
    // onDrop={onDrop}
    // onMouseDown={onMouseDown}
    // onMouseMove={onMouseMove}
    // onTouchStart={onMouseDown}
    // onDragOver={(event:any) =>
    //     event.preventDefault()
    // }
    // {...bindDoubleTap}
    >
        {children}
    </div>
});

export default Container;