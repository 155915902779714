import {
    observer
} from 'mobx-react-lite';
import WeddingSpace from '../../../api/space';
import { ImageBlock, TextBlock, TitleBlock } from '../blocks';
import EditorAddComponent from '../editor/EditorAddComponent';
import { IconButton } from '../../controls';
import EditorComponentToolbar from '../editor/EditorComponentToolbar';
import DjWhishBlock from '../blocks/DjWhish';

const SpaceEditComponentButton = observer(({ sdk, component }: any) => {
    const useSpace: WeddingSpace | null = sdk?.space || null;

    return useSpace?.editMode ? <IconButton
        id={`canvas-imageblock-${component?.id || ''}-edit`}
        source={'icon:edit.svg'}
        buttonOption={{
            widthPx: 64,
            heightPx: 64,
            position: 'absolute',
            top: 0,
            right: 8
        }}
        onClick={() => sdk.space.editor?.selectComponent(component.id)}
    /> : null;
})

const SpaceComponent = observer(({ sdk, component, lastComponent, index }: any) => {
    const useSpace: WeddingSpace | null = sdk?.space || null;
    const isEditMode: boolean = useSpace?.editMode || false;
    const hasSelectedComponent: boolean = useSpace?.editor?.activeComponent !== null;
    const isSelectedComponent: boolean = useSpace?.editor?.activeComponent === component.id;
    const hasEditorToolbar: boolean = isEditMode && isSelectedComponent;

    const setInBackground: boolean = isEditMode && hasSelectedComponent && !isSelectedComponent;

    return <>
        {hasEditorToolbar ? <EditorComponentToolbar sdk={sdk} component={component} direction={'up'} movePossible={index !== 0} /> : null}
        <TitleBlock sdk={sdk} component={component} editable={isSelectedComponent} inBackground={setInBackground}  />
        {component?.type === 'image' ? <ImageBlock sdk={sdk} component={component} editable={isSelectedComponent} inBackground={setInBackground}  ><SpaceEditComponentButton sdk={sdk} component={component} /></ImageBlock> : null}
        {component?.type === 'text' ? <TextBlock sdk={sdk} component={component} editable={isSelectedComponent} inBackground={setInBackground}><SpaceEditComponentButton sdk={sdk} component={component} /></TextBlock> : null}
        {component?.type === 'djwhish' ? <DjWhishBlock sdk={sdk} component={component} editable={isSelectedComponent} inBackground={setInBackground}><SpaceEditComponentButton sdk={sdk} component={component} /></DjWhishBlock> : null}
        {hasEditorToolbar ? <EditorComponentToolbar sdk={sdk} component={component} direction={'down'} movePossible={lastComponent !== true} /> : null}
    </>;
});


export default SpaceComponent;