import { action, computed, makeObservable, observable } from "mobx";
import { insertDataIntoArray, scrollToBottom, uuidv4 } from "../../helper";
import WeddingSpace from ".";

class WeddingSpaceEditor {
    public activeComponent: string | null;
    public layout: any;

    public colorBackground: string = '';
    public colorPrimary: string = '';
    public colorSecondary: string = '';
    public colorTertiary: string = '';
    public colorPrimaryText: string = '';
    public colorSecondaryText: string = '';

    public selectedColor: string;

    public title: string = '';
    public icon: string = '';

    public owner: OwnerStore;
    public flagOwnerTitle: number = 0;

    constructor() {
        makeObservable(this, {
            title: observable,
            icon: observable,
            flagOwnerTitle: observable,
            layout: observable,
            owner: observable,
            colors: computed,
            activeComponent: observable,
            colorBackground: observable,
            colorPrimary: observable,
            colorSecondary: observable,
            colorTertiary: observable,
            colorPrimaryText: observable,
            colorSecondaryText: observable,
            selectedColor: observable,
            selectColorPicker: action,
            setTitleVisibility: action,
            changeComponentTitle: action,
            changeComponentValue: action,
            selectComponent: action,
            updateValue: action,
            update: action,
            addComponent: action,
            deleteComponent: action,
            moveComponentUp: action,
            moveComponentDown: action,
            data: computed,
            hashSpace: computed,
            activeComponentIndex: computed
        })

        this.activeComponent = null;
        this.selectedColor = 'colorPrimary';
        this.flagOwnerTitle = 0;
        this.owner = new OwnerStore();
    }

    public updateValue(property: string, value: number) {
        // @ts-ignore
        this[property] = value;
    }

    public selectColorPicker(colorId: string): void {
        this.selectedColor = colorId;
    }

    public selectComponent(id: string | null): void {
        if (this.activeComponent === id) {
            this.activeComponent = null;
        } else {
            this.activeComponent = id;
        }
    }

    public async update(space: WeddingSpace): Promise<void> {
        const {
            palette,
            layout
        } = space;
        this.layout = layout.map((comp: any) => comp);

        if (palette?.colors) {
            this.colorBackground = palette.colors.background;
            this.colorPrimary = palette.colors.primary;
            this.colorSecondary = palette.colors.secondary;
            this.colorTertiary = palette.colors.tertiary;
            this.colorPrimaryText = palette.colors.primaryText;
            this.colorSecondaryText = palette.colors.secondaryText;
        }

        this.title = space.title;
        this.icon = space.icon;
        this.flagOwnerTitle = space.flagOwnerTitle;
        this.owner.sync(space.owner);
    }

    public async deleteComponent(componentId: string = ''): Promise<boolean> {
        const useComponentId: string = componentId || this.activeComponent || '';
        this.layout = this.layout.filter((comp: any) => comp.id !== useComponentId);
        this.activeComponent = null;
        return true;
    }

    public async moveComponentUp(componentId: string): Promise<boolean> {
        const lookForIndex: number = this.layout.findIndex((comp: any) => comp.id === componentId);

        if (lookForIndex === -1) return false;
        if (lookForIndex === 0) return false; // first component cannot move

        // switch the components
        const tmp: any = this.layout[lookForIndex - 1];
        this.layout[lookForIndex - 1] = this.layout[lookForIndex];
        this.layout[lookForIndex] = tmp;

        return true;
    }

    public async moveComponentDown(componentId: string): Promise<boolean> {
        const lookForIndex: number = this.layout.findIndex((comp: any) => comp.id === componentId);

        if (lookForIndex === -1) return false;
        if (lookForIndex + 1 === this.layout.length) return false; // latest component cannot move

        // switch the components
        const tmp: any = this.layout[lookForIndex + 1];
        this.layout[lookForIndex + 1] = this.layout[lookForIndex];
        this.layout[lookForIndex] = tmp;

        return true;
    }

    public async addComponent(addBeforeId: string, tile: any): Promise<boolean> {
        const lookForIndex: number = this.layout.findIndex((comp: any) => comp.id === addBeforeId);
        const newComponent = {
            id: uuidv4(),
            type: tile.type,
            ...(tile.config || {})
        };

        if (lookForIndex === -1) {
            this.layout.push(newComponent);
        } else {
            this.layout = insertDataIntoArray(this.layout, newComponent, lookForIndex)
        }

        this.selectComponent(newComponent.id);

        setTimeout(() => {
            scrollToBottom('blockarea-container');
        }, 100);
        return true;
    }

    public async setTitleVisibility(componentId: string, visibility: boolean): Promise<boolean> {
        this.layout = this.layout.map((comp: any) => ({
            ...comp,
            title: {
                ...comp.title,
                active: comp.id === componentId ? visibility : comp.title.active
            }
        }));
        return true;
    }

    public async changeComponentTitle(componentId: string, value: string): Promise<boolean> {
        this.layout = this.layout.map((comp: any) => ({
            ...comp,
            title: {
                ...comp.title,
                value: comp.id === componentId ? value : comp.title?.value
            }
        }));
        return true;
    }

    public async changeComponentObjectValue(componentId: string, valueProperty: string, newValue: string): Promise<boolean> {
        this.layout = this.layout.map((comp: any) => ({
            ...comp,
            value: comp.id === componentId ? {
                ...comp.value,
                [valueProperty]: newValue
            } : comp.value
        }));
        return true;
    }

    public async changeComponentValue(componentId: string, value: string): Promise<boolean> {
        this.layout = this.layout.map((comp: any) => ({
            ...comp,
            value: comp.id === componentId ? value : comp.value
        }));
        return true;
    }

    get hashSpace() {
        return JSON.stringify({
            layout: this.layout,
            colors: this.colors,
            owner: this.owner.toArray(),
            flagOwnerTitle: this.flagOwnerTitle,
            title: this.title,
            icon: this.icon
        })
    }

    get colors() {
        return {
            background: this.colorBackground,
            primary: this.colorPrimary,
            secondary: this.colorSecondary,
            tertiary: this.colorTertiary,
            primaryText: this.colorPrimaryText,
            secondaryText: this.colorSecondaryText
        }
    }

    get data() {
        return {
            layout: this.layout,
            colors: this.colors,
            title: this.title,
            icon: this.icon,
            owner: this.owner.toArray(),
            flags: {
                ownerTitle: this.flagOwnerTitle
            }
        }
    }

    get activeComponentIndex() {
        if (this.activeComponent === null) return null;
        return this.layout.findIndex((comp: any) => comp.id === this.activeComponent)
    }
}

class OwnerStore {
    public ownerA: string;
    public ownerB: string;

    constructor() {
        makeObservable(this, {
            ownerA: observable,
            ownerB: observable,
            updateValue: action,
            sync: action
        })

        this.ownerA = '';
        this.ownerB = '';
    }

    public updateValue(property: string, value: number) {
        // @ts-ignore
        this[property] = value;
    }

    public sync(values: Array<string>): void {
        this.ownerA = values?.[0] || '';
        this.ownerB = values?.[1] || '';
    }

    public toArray(): any {
        return [
            this.ownerA,
            this.ownerB
        ]
    }
}

export default WeddingSpaceEditor;