import {
    observer
} from 'mobx-react-lite';
// import { useEffect } from 'react';
import {
    Container,
    IconButton
} from '../controls/index';
import { getImageMedia, getImageMediaPreview, getImageOrig } from '../../helper';

const ImageDialog = observer(({ sdk }: any) => {
    const {
        layoutManager
    } = sdk;

    const dialog = sdk.dialog?.type === 'image' ? sdk.dialog : null;
    const file = dialog?.file || null;

    return file ? <div
        id={'gamedialog'}
        onClick={() => null}
        style={{}}
    >
        <div
            id={'gamedialog_image_wrapper'}

            key={1}
            style={layoutManager.calculateCanvas}
            onClick={() => sdk.closeDialog()}
        >

            <img
                // id={id}
                // style={propsImage}
                className={'imagedialog'}
                src={getImageMediaPreview(file.uuid, 'jpg')}
                alt={'Media Preview'}
            // onClick={() => us}
            // onError={(e) => {
            //     if (errorSource) {
            //         // @ts-ignore
            //         e.target.src = getImageSrc(errorSource);
            //     }
            // }}
            />
            <Container
                containerOption={{
                    position: 'absolute',
                    bottom: 0,
                    widthPx: 256,
                    heightPx: 96,
                    lineHeight: 80,
                    bgColor: '#855495',
                    fontSize: 32,
                    color: '#fff',
                    zIndex: 40
                }}
                onClick={(event: any) => {
                    event.stopPropagation();
                    event.preventDefault();
                    window.open(getImageOrig(file.uuid, 'jpg'), '_blank');
                }}
            >
                Download
            </Container>
        </div>
    </div > : null;
});

export default ImageDialog;