import {
    observer
} from 'mobx-react-lite';
import Container from '../../../controls/components/Container';
import ImageComponent from '../../../controls/components/Image';
import { getMediaSrc, isValidUrl } from '../../../../helper';
import { IconSvg, ImagePicker, TextField } from '../../../controls';
import WeddingSpace from '../../../../api/space';
import WeddingSpaceEditor from '../../../../api/space/editor';
import { useState } from 'react';
import { ApplicationSdk } from '../../../../api';

const DjWhishBlock = observer(({
    sdk,
    component,
    editable,
    children,
    inBackground = false
}: { sdk: ApplicationSdk, component: any, editable: boolean, children: any, inBackground: boolean }) => {
    const [whish, setWhish] = useState('');

    const useSpace: WeddingSpace | null = sdk?.space || null;
    const useSpaceEditor: WeddingSpaceEditor | null = useSpace?.editor || null;

    return <Container
        id={`canvas-imageblock-${component?.id || ''}`}
        containerOption={{
            position: 'relative',
            float: 'left',
            width: '100%',
            opacity: inBackground ? 0.2 : 1
        }}
    >
        {editable ? (
            <ImagePicker
                id={'upload-dj-image'}
                onHandleUpload={(file: any) => sdk.space ? sdk.space.uploadMedia('dj', file) : null}
                multiple={false}
                displayName={'Dj Bild'}
                media={getMediaSrc(useSpace, 'dj', 'test:dj')}
                aspectRatio={[4, 4]}
                containerOption={{
                    float: 'left',
                    widthPx: 192,
                    heightPx: 192,
                    borderRadiusPercent: 50,
                    margin: [32, 16, 16, 16]
                }}
            ></ImagePicker>
        ) : (
            <ImageComponent
                id={`dj-whishbox-image`}
                source={getMediaSrc(useSpace, 'dj', 'test:dj')}
                imageOptions={{
                    float: 'left',
                    widthPx: 192,
                    heightPx: 192,
                    borderRadiusPercent: 50,
                    margin: [32, 16, 16, 16]
                }}
            />
        )}
        <Container
            containerOption={{
                widthPx: 480,
                float: 'left',
                heightPx: 240,
                lineHeight: 80,
                fontSize: 30,
                color: useSpace?.palette?.primaryText
            }}
        >
            <Container
                id={'dj-whishbox-header'}
                containerOption={{
                    widthPx: 440,
                    float: 'left',
                    heightPx: 80,
                    lineHeight: 80,
                    fontSize: 30,
                    color: useSpace?.palette?.primaryText,
                    margin: [16, 0, 0, 20],
                    textAlign: 'left'
                }}
            >
                {editable ? (
                    <TextField
                        value={component?.value?.header || ''}
                        inputOption={{
                            width: '100%',
                            heightPx: 48,
                            lineHeight: 48,
                            textAlign: 'left'
                        }}
                        onInput={(value: string) => useSpaceEditor?.changeComponentObjectValue(component.id, 'header', value)}
                    />
                ) : (component?.value?.header || '-')}
            </Container>
            <Container
                containerOption={{
                    widthPx: 440,
                    margin: [0, 0, 0, 20],
                    float: 'left',
                    heightPx: 80,
                    lineHeight: 80,
                    fontSize: 22
                }}
            >
                {editable ?
                    (
                        <TextField
                            value={component?.value?.placeholder || ''}
                            inputOption={{
                                width: '100%',
                                heightPx: 48,
                                lineHeight: 48,
                                textAlign: 'left'
                            }}
                            onInput={(value: string) => useSpaceEditor?.changeComponentObjectValue(component.id, 'placeholder', value)}
                        />
                    )
                    :
                    (
                        <>
                            <TextField
                                id={'whishinput'}
                                maxLength={120}
                                value={whish}
                                placeholder={(component?.value?.placeholder || '-')}
                                inputOption={{
                                    widthPx: 320,
                                    float: 'left',
                                    fontSize: 24,
                                    lineHeight: 48,
                                    borderRadius: 16,
                                    bgColor: useSpace?.palette?.secondary,
                                    color: useSpace?.palette?.secondaryText,
                                    // margin: [0, 0, 0, 20]
                                }}
                                onInput={(value: string) => setWhish(value)}
                            />
                            <IconSvg
                                icon={'svg>sendmessage'}
                                iconOption={{
                                    widthPx: 64,
                                    heightPx: 64,
                                    margin: [8, 0, 0, 16],
                                    float: 'left'
                                }}
                                color={useSpace?.palette?.tertiary}
                                onClick={() => {
                                    if (useSpace?.editMode) {
                                        sdk.openDialog('Achtung: EditierModus', 'Du befindest dich im Editier-Modus. Diese Funktion ist nur in der aktiven Ansicht vorhanden');
                                    } else {
                                        if (useSpace?.whishList) {
                                            useSpace.whishList.create(whish);
                                            setWhish('');
                                        }
                                    }
                                }}


                            />
                        </>
                    )}

                {/* // disabled={!whish?.length || whish.length < 3}
            // onClick={() => {
            //     sdk.space.whishList.create(whish)
            //     setWhish('')
            // */}
            </Container>
            <Container
                id={'dj-whishbox-footer'}
                containerOption={{
                    widthPx: 440,
                    margin: [0, 0, 0, 20],
                    float: 'left',
                    lineHeight: 64,
                    heightPx: 64,
                    fontSize: 22,
                    textAlign: 'left'
                }}
            >
                {editable ? (
                    <TextField
                        value={component?.value?.footer || ''}
                        inputOption={{
                            width: '100%',
                            heightPx: 32,
                            lineHeight: 32,
                            textAlign: 'left'
                        }}
                        onInput={(value: string) => useSpaceEditor?.changeComponentObjectValue(component.id, 'footer', value)}
                    />
                ) : isValidUrl(component?.value?.footer) ? (
                    <a
                        href={component.value.footer}
                        target={'_blank'}
                        rel="noreferrer"
                    >
                        {new URL(component.value.footer).hostname}
                    </a>
                ) : component.value.footer}
            </Container>
        </Container>

        {editable ? null : children}
    </Container>;
});

export default DjWhishBlock;