import { action, computed, makeObservable, observable } from "mobx";
import getSdk, { ApplicationSdk } from "..";

class WeddingGallery {
    public spaceId: number;
    public currentSite: number;
    public latestFetch: number | null;

    public images: any[];
    public imageCount: number;
    public siteCount: number;

    constructor(spaceId: number) {
        makeObservable(this, {
            currentSite: observable,
            images: observable,
            imageCount: observable,
            siteCount: observable,
            fetchGallery: action,
            previousPage: action,
            nextPage: action,
            allImages: computed,
            hasNextSite: computed,
            hasPrevSite: computed
        });

        this.spaceId = spaceId;
        this.images = [];
        this.imageCount = 0;
        this.currentSite = 1;
        this.siteCount = 1;
        this.latestFetch = null;

    }

    get allImages() {
        return this.images.map((i: any) => i);
        // return allImg.reverse();
    }

    public async uploadImage(files: any): Promise<any> {
        const useSdk: ApplicationSdk = getSdk();

        if (files) {

            const uploadResult = await useSdk.fetchUploadFile(
                `space/${this.spaceId}/media/upload`,
                files
            );

            if (uploadResult?.status === 201) {
                this.fetchGallery(true);
            }
        }

    }

    public async deleteImage(img: any): Promise<any> {
        const useSdk: ApplicationSdk = getSdk();

        useSdk.openDeleteDialog('Bild löschen', 'Möchtest du das Bild wirklich löschen? Dann gebe "ja" ein', 'ja', async () => {
            const spinner = useSdk.createSpinner('Lösche Bild...');
            const deleteResult = await useSdk.fetchDeleteApi(
                `space/${this.spaceId}/media/remove/${img.id}`
            );
            spinner.close();
            if (deleteResult.status === 200) {
                this.fetchGallery(true);
            }
        });

    }

    public async fetchGallery(force: boolean = false, spinner: boolean = false): Promise<any> {

        if (!force && this.latestFetch) {
            const diff: number = Date.now() - this.latestFetch;
            if (diff < 5000) {
                return;
            }
        }

        this.latestFetch = Date.now();

        const useSdk: ApplicationSdk = getSdk();
        let useSpinner: any = null;
        if (spinner) {
            useSpinner = useSdk.createSpinner('Lade Bilder...');
        }

        const galleryResult: any = await useSdk.fetchGetApi(`space/${this.spaceId}/media/image/${this.currentSite}`)

        if (useSpinner) {
            useSpinner.close();
        }

        this.images = galleryResult?.json?.media || [];
        this.imageCount = galleryResult?.json?.mediaCount || 0;
        this.siteCount = galleryResult?.json?.pageCount || 1;
    }

    get hasNextSite() {
        return this.currentSite < this.siteCount;
    }

    get hasPrevSite() {
        return this.currentSite > 1;
    }

    public async nextPage(lastPage: boolean = false) {
        if (this.hasNextSite) {
            this.currentSite = lastPage ? this.siteCount : this.currentSite + 1;
            this.fetchGallery(true, true);
        }
    }

    public async previousPage(firstPage: boolean = false) {
        if (this.hasPrevSite) {
            this.currentSite = firstPage ? 1 : this.currentSite - 1;
            this.fetchGallery(true, true);
        }
    }
}

export default WeddingGallery;