/* eslint-disable */
// @ts-nocheck

import Compressor from "compressorjs";

interface StyelSheetChanger {
    a?: StyleSheetObjectOptions;
    ['a:hover']?: StyleSheetObjectOptions;
    ['.gameinput::placeholder']?: StyleSheetObjectOptions;
    ['::-webkit-scrollbar-track']?: StyleSheetObjectOptions;
    ['::-webkit-scrollbar-thumb']?: StyleSheetObjectOptions;
    ['::-webkit-scrollbar-thumb:hover']?: StyleSheetObjectOptions;
} // also add the scrllbar

interface StyleSheetObjectOptions {
    color?: string;
    background?: string;
}

export const getImageSrc = (img: string) => {
    let useImage = img;

    if (useImage?.includes('http') || useImage?.includes('https')) {
        return useImage;
    }

    if (useImage?.includes(':')) {
        const splitPath = img.split(':');

        useImage = `${process.env.REACT_APP_APPMEDIA}data/${splitPath[0]}/${splitPath[1]}${(splitPath[1].includes('.jpg') || splitPath[1].includes('.svg')) ? '' : '.png'}`;
    }

    return useImage;
}

export const getMediaFileExtension = (extension: string) => {
    if (extension === 'image/png') {
        return 'png';
    } else if (extension === 'image/jpeg') {
        return 'jpg';
    }
    return 'jpg';
}

export const scrollToBottom = (id) => {
    const element = document.getElementById(id);
    element.scrollTop = element.scrollHeight;
}

export const getMediaSrc = (space: any, imageType: string, defaultImg: any) => {
    if (space.media[imageType]) {
        return `${process.env.REACT_APP_MEDIA_URL}${space.type}/${space.hash}/${space.uuid}/${imageType}.${getMediaFileExtension()}?${space.media[imageType].fileSize}`;
    }
    return defaultImg;
}

export const getImageOrig = (fileUUID: string, fileExtension: string, space: string = 'york+nadine') => {
    return `${process.env.REACT_APP_WEDDINGWONDERS_MEDIA}${space}/${fileUUID}-orig.${fileExtension}`;
}

export const getImageMedia = (fileUUID: string, fileExtension: string, space: string = 'york+nadine') => {
    return `${process.env.REACT_APP_WEDDINGWONDERS_MEDIA}${space}/${fileUUID}-thumb.${fileExtension}`;
}

export const getImageMediaPreview = (fileUUID: string, fileExtension: string, space: string = 'york+nadine') => {
    return `${process.env.REACT_APP_WEDDINGWONDERS_MEDIA}${space}/${fileUUID}-preview.${fileExtension}`;
}

export const uuidv4 = () => {
    return 'xxxxxxxxxxxx4xxxyxxxxxxx3xxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export const adjust = (color, amount) => {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}
export const elapsedTimeString = (timeMin: any) => {
    if (timeMin <= 1) {
        return 'gerade eben'
    } else if (timeMin < 60) {
        return `vor ${timeMin} Minuten`
    } else if (timeMin < 1440) {
        return `vor ${Math.floor(timeMin / 60)} Stunden`
    } else {
        return `vor ${Math.floor(timeMin / 1440)} Tage`
    }
}

export const insertDataIntoArray = (array: Array<any>, val: any, ind: number) => {
    return ind >= array.length
        ? array.concat(val)
        : array.reduce(
            (accumulator, currentValue, i) => accumulator.concat(i === ind ? [val, currentValue] : currentValue),
            [],
        );
}

export const rgbToHex = (r: number, g: number, b: number) => {
    return "#" + (1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1);
}

export const hexToRgb = (hex: string) => {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

export const invertHexColor = (hexColor: string) => {
    const color: any = hexToRgb(hexColor);
    if (color !== null) {
        return rgbToHex(255 - color.r, 255 - color.g, 255 - color.b);
    }
    return hexColor;
}

export const changeStylesheetProperties = (styleChanger: StyelSheetChanger) => {

    const sheets = document.styleSheets;
    const slen = sheets.length;

    for (let i = 0; i < slen; i++) {

        const rules = document.styleSheets[i].cssRules;
        const rlen = rules.length;

        for (let j = 0; j < rlen; j++) {
            if (styleChanger[rules[j].selectorText]) {
                if (styleChanger[rules[j].selectorText]?.color) {
                    rules[j].style['color'] = styleChanger[rules[j].selectorText].color;
                }
                if (styleChanger[rules[j].selectorText]?.background) {
                    rules[j].style['background'] = styleChanger[rules[j].selectorText].background;
                }
            }
        }
    }
}

export const isValidUrl = (uri: string): boolean => {
    try {
        new URL(uri);
        return true;
    } catch (err) {
    }
    return false;
}

export const createImage = (url) => {
    const image = new Image()
    image.src = url;
    return image;
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width, height, rotation) {
    const rotRad = getRadianAngle(rotation)

    return {
        width:
            Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
        height:
            Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
    }
}

export function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180
}

export default async function getCroppedImg(
    imageSrc,
    pixelCrop,
    rotation = 0,
    flip = { horizontal: false, vertical: false }
) {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    if (!ctx) {
        return null
    }

    const rotRad = getRadianAngle(rotation)

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
        image.width,
        image.height,
        rotation
    )

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth
    canvas.height = bBoxHeight


    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
    ctx.rotate(rotRad)
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
    ctx.translate(-image.width / 2, -image.height / 2)

    // draw rotated image
    ctx.drawImage(image, 0, 0)

    const croppedCanvas = document.createElement('canvas')

    const croppedCtx = croppedCanvas.getContext('2d')

    if (!croppedCtx) {
        return null
    }

    // Set the size of the cropped canvas
    croppedCanvas.width = pixelCrop.width
    croppedCanvas.height = pixelCrop.height

    // Draw the cropped image onto the new canvas
    croppedCtx.drawImage(
        canvas,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
    )

    // As Base64 string
    // return croppedCanvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve, reject) => {
        croppedCanvas.toBlob((file: any) => {
            const myFile = new File([file], 'image.jpeg', {
                type: file.type,
            });
            resolve(myFile)
        }, 'image/jpeg')
    })
}

export const getNormalizedFile = async (file: any) => {
    return new Promise((resolve, reject) => {
        new Compressor(file, {
            maxWidth: 2048,
            maxHeight: 2048,
            success(normalizedFile) {
                resolve(normalizedFile)
            },
            error(error) {
                reject(error)
            },
        })
    })
}

export const readFileToNormalize = (file: any) => {
    return new Promise((resolve, reject) => {
        try {
            getNormalizedFile(file)
                .then(normalizedFile => resolve(normalizedFile))
                .catch(error => reject(error))
        } catch (error) {
            reject(error)
        }
    })
}