import {
    observer
} from 'mobx-react-lite';
import { Container, IconButton, IconSvg } from '../../controls';
import WeddingSpaceEditor from '../../../api/space/editor';
import WeddingSpace from '../../../api/space';
import { invertHexColor } from '../../../helper';


const EditorToolbarActiveComponent = observer(({ space, sdk, color }: any) => {
    return <>
        <IconButton
            buttonOption={{
                widthPx: 64,
                heightPx: 64,
                margin: [8, 0, 0, 16],
                float: 'left'
            }}
            source={'icon:delete.svg'}
            onClick={() => space?.editor?.deleteComponent()}
        />
        <Container
            className={'heartbeat'}
            containerOption={{
                widthPx: 420,
                heightPx: 64,
                lineHeight: 64,
                margin: [8, 0, 0, 80],
                fontWeight: 'bold',
                float: 'left',
                textAlign: 'center'
            }}
        >
            *** Editier Modus ***
        </Container>
        <IconButton
            buttonOption={{
                widthPx: 64,
                heightPx: 64,
                margin: [8, 16, 0, 0],
                float: 'right'
            }}
            source={'icon:close.svg'}
            onClick={() => space.editor?.selectComponent(null)}
        />
    </>
});

const EditorToolbarGeneral = observer(({ space, sdk, color }: any) => {
    return <>
        <IconSvg
            icon={'svg>setting'}
            iconOption={{
                widthPx: 64,
                heightPx: 64,
                margin: [8, 0, 0, 16],
                float: 'left'
            }}
            color={color}
            onClick={() => sdk.openSetting()}
        />
        <IconSvg
            icon={'svg>save'}
            iconOption={{
                widthPx: 64,
                heightPx: 64,
                margin: [8, 16, 0, 0],
                float: 'right'
            }}
            color={color}
            disabled={space?.hasEditorUpdate === false}
            onClick={() => space?.save()}
        />
    </>
});

const EditorToolbar = observer(({ sdk }: any) => {
    const useSpace: WeddingSpace | null = sdk?.space || null;
    const useSpaceEditor: WeddingSpaceEditor | null = useSpace?.editor || null;
    const hasActiveComponent = useSpaceEditor?.activeComponent || null;
    const useBgColor: string = useSpace?.palette?.tertiary || '#ff00ff';
    const useFontColor: string = invertHexColor(useBgColor);

    return useSpaceEditor ? <Container
        containerOption={{
            heightPx: 80,
            bgColor: useBgColor,
            color: useFontColor,
            position: 'sticky',
            zIndex: 32
        }}
    >
        {hasActiveComponent ?
            <EditorToolbarActiveComponent
                sdk={sdk}
                space={useSpace}
                color={useFontColor}
            /> :
            <EditorToolbarGeneral
                sdk={sdk}
                space={useSpace}
                color={useFontColor}
            />}

    </Container> : null;
});


export default EditorToolbar;