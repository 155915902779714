import {
    observer
} from 'mobx-react-lite';
import type WeddingSpace from '../../../api/space';
import { Container } from '../../controls';
import HeadImageBlock from '../blocks/HeadImageBlock';
import { HeadlineTitleBlock } from '../blocks';
import SpaceComponent from './SpaceComponent';
import EditorToolbar from '../editor/EditorToolbar';
import DjAdminBlock from '../dj';

const SpaceView = observer(({ sdk }: any) => {
    const useSpace: WeddingSpace | null = sdk?.space || null;

    return useSpace ? (
        <Container
            id={'blockarea'}
            containerOption={{
                width: '100%',
                height: '100%',
                overflowX: 'hidden',
                overflowY: useSpace.view === null ? 'auto' : 'hidden',
                position: 'relative',
                bgColor: useSpace?.palette?.primary
            }}
        >
            {useSpace.view === null ? (
                <>
                    <EditorToolbar sdk={sdk} />
                    <HeadImageBlock sdk={sdk} />
                    <HeadlineTitleBlock sdk={sdk} />
                    {useSpace?.blocks?.map((component: any, index: number) => <SpaceComponent
                        sdk={sdk}
                        component={component}
                        key={index}
                        index={index}
                        lastComponent={index === useSpace?.blocks?.length - 1}
                    />)}
                </>
            ) : <DjAdminBlock sdk={sdk} />}

        </Container>
    ) : null;
});


export default SpaceView;
export {
    SpaceComponent
}